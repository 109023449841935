import * as S from './styles';
import { Checkbox, Form } from 'semantic-ui-react';
import { useFormGeneric, useAutoSaveContext } from '~/hooks';
import { ReactNode, useEffect, CSSProperties } from 'react';
import FieldErrorMessage from './FieldErrorMessage';
import FieldLabel from './FieldLabel';

export interface CheckboxGroupProps {
  schemaKey?: string
  hideErrors?: boolean
  fieldLabel?: string
  values?: {
    label: string
    value: boolean | number | string
    disabled?: boolean
  }[]
  defaultValue?: number | string
  inline?: boolean
  stretched?: boolean
  checkboxStyle?: {
    [key: string]: string
  }
  fieldStyle?: {
    [key: string]: string
  }
  helpText?: string | ReactNode
  helpTextStyle?: CSSProperties
  onChange?: Function
  context?: any
  children?: ReactNode
  optional?: boolean
}

function CheckboxGroupImpl({
  context,
  hideErrors,
  schemaKey,
  onChange,
  values,
  fieldLabel,
  checkboxStyle = {},
  fieldStyle = {},
  inline = false,
  stretched = false,
  helpText,
  children,
  defaultValue,
  optional,
  ...props
}: CheckboxGroupProps) {
  let { updateValue, getValue, errors } = context;
  const error = errors[schemaKey as string];

  if (onChange) {
    updateValue = onChange;
  }

  const value = getValue(schemaKey);
  useEffect(() => {
    if (!value && defaultValue) {
      updateValue(schemaKey, defaultValue, { doSave: false });
    }
  }, [value, defaultValue]);

  return (
    <S.CheckboxContainer className="checkbox-container" stretched={stretched}>
      {fieldLabel && (
        <Form.Field className="label" error={!!error}>
          <FieldLabel helpText={helpText} text={fieldLabel} optional={optional} />
        </Form.Field>
      )}
      <Form.Group {...props} id={schemaKey} error={!!error} inline={inline}>
        {values?.map((checkbox) => {
          return (
            <Form.Field
              style={{...fieldStyle}}
              key={checkbox.value as string}
            >
              <Checkbox
                id={`${schemaKey}.${checkbox.value}`}
                style={{...checkboxStyle}}
                onChange={() => {
                  if (value.includes(checkbox.value)) {
                    // If the checkbox is checked, uncheck it by removing it from value array
                    updateValue(schemaKey, value.filter((v: any) => v !== checkbox.value), { doSave: false });
                  } else {
                    // If the checkbox is unchecked, check it by adding it to value array
                    updateValue(schemaKey, [...value, checkbox.value], { doSave: false });
                  }
                }}
                checked={value?.includes(checkbox.value)}
                label={checkbox.label}
                value={checkbox.value as string}
                disabled={checkbox.disabled}
              />
            </Form.Field>
          );
        })}
        {children}
        <FieldErrorMessage content={error} />
      </Form.Group>
    </S.CheckboxContainer>
  );
}

// this could be much more concise by relying entirely on ...props, but doing this facilitates IDE autocompletion
export function FormCheckboxGroup({
  schemaKey, onChange, values, checkboxStyle, fieldStyle, ...props
}: CheckboxGroupProps) {
  return (
    <CheckboxGroupImpl
      context={useFormGeneric()}
      {...{schemaKey, onChange, values, checkboxStyle, fieldStyle}}
      {...props}
    />
  );
}

// this could be much more concise by relying entirely on ...props, but doing this facilitates IDE autocompletion
export function AutoSaveCheckboxGroup({
  schemaKey, onChange, values, checkboxStyle, fieldStyle, ...props
}: CheckboxGroupProps) {
  return (
    <CheckboxGroupImpl
      context={useAutoSaveContext()}
      {...{schemaKey, onChange, values, checkboxStyle, fieldStyle}}
      {...props}
    />
  );
}
