import * as S from './styles';
import Link from '~/components/Link';
import { FormNumberInput, FormSchemaAwareWidget } from '~/components/form';
import { 
  ContractType, 
  PricingType, 
  Unit, 
  UnitDecimals,
  InvestorFeedbackBidStatus 
} from '~/constants';
import { useProjectContext } from '~/hooks';
import { FormContextProvider, useForm } from '~/hooks/providers/FormContextProvider';
import { InputType } from '~/schema';
import { Button } from '~/components';
import { postProjectBid } from '~/api/projectBids';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import BidConfirmationModal from './modals/BidConfirmationModal';
import budget from '~/utils/displayValues/project/budget';
import { calculateProjectCosts } from '~/utils/calculators';
import { usePermissions } from '~/requests/permissions/usePermissions';
import useArchiveToast from '~/hooks/useArchiveToast';
import InvestorFeedbackModal from './modals/BidFeedback';
import toast from 'react-hot-toast';

const BidForm = () => {
  const { formState, updateFormValue } = useForm() as any;
  const { permissions } = usePermissions();
  const { project, id, fetchProject } = useProjectContext();
  const { showArchiveToast } = useArchiveToast();
  const [projectPricingType, setProjectPricingType] = useState(project?.pricingType) as any;
  const [modalType, setModalType] = useState<null | 'BID_CONFIRMATION' | 'FEEDBACK_PENDING_BID' | 'FEEDBACK_DECLINED_BID'>(null);
  const closeModal = () => setModalType(null);
  const history = useHistory();

  const handleBidSubmit = () => {
    fetchProject();
    // if there are no bids and the project is a standalone project, prompt investor for feedback
    if (!project?.projectBids?.length && !project?.portfolioId) {
      setModalType('FEEDBACK_PENDING_BID');
    } else if (project?.portfolioId) {
      history.push(`/portfolio/${project.portfolioId}`);
    }

    updateFormValue('amount', '');
    updateFormValue('investorAcknowledgmentsFlag', false);
    updateFormValue('unit', project?.pricingType === PricingType.OFFTAKE ? (project?.contractType === ContractType.PPA ? Unit.KWH : Unit.YEAR) : Unit.WDC);
    setProjectPricingType(project?.pricingType);
  };

  const { mutate: submitBid, status: submitBidStatus } = useMutation(
    async () => {
      await postProjectBid(formState, id);
      handleBidSubmit();
      toast.success('Thank you for submitting your bid.', { duration: 5000 });
    }
  );

  const projectCosts =  calculateProjectCosts(project, {
    perWdc: true,
    overall: true,
    nrsFee: true,
    bid: formState
  });

  const budgetDisplayValues = budget(project, projectCosts, permissions);

  return (
    <>
      <S.BidForm>
        <FormNumberInput
          schemaKey="amount"
          fieldLabel='My bid'
          decimals={project?.pricingType === PricingType.OFFTAKE ? (project?.contractType === ContractType.PPA ? 4 : 0) : undefined}
          label={project?.pricingType === PricingType.OFFTAKE ? (project?.contractType === ContractType.PPA ? 'per kWh' : 'per year') : undefined}
          numberType={InputType.DOLLARS}
          unitField="unit"
          units={project?.pricingType === PricingType.ACQUISITION ? [
          {value: Unit.WDC, label: 'per Wdc', decimals: UnitDecimals.WDC},
          {value: Unit.TOTAL, label: 'total', decimals: UnitDecimals.TOTAL},
          ] : undefined}
        />

        <S.BidFormCheckbox>
          <FormSchemaAwareWidget schemaKey="investorAcknowledgmentsFlag" />
          <label htmlFor="investorAcknowledgmentsFlag" >
            I’ve reviewed the <Link to="/acknowledgements" primary underline bold>Investor’s Acknowledgment</Link>
          </label>
        </S.BidFormCheckbox>

        <S.BidFormActions>
          <Button 
            primary 
            onClick={() => {
              if (!showArchiveToast()) { 
                setModalType('BID_CONFIRMATION');
              }}
            }
            disabled={!formState.investorAcknowledgmentsFlag}
          >
            Submit bid
          </Button>
          <Button 
            primary 
            outlined
            onClick={() => {
              if (!showArchiveToast()) { 
                setModalType('FEEDBACK_DECLINED_BID');
              }}
            }
          >
            Decline to bid
          </Button>
        </S.BidFormActions>
      </S.BidForm>

      {modalType === 'BID_CONFIRMATION' && 
        <BidConfirmationModal 
          submitBid={submitBid}
          submitBidStatus={submitBidStatus}
          budgetDisplayValues={budgetDisplayValues}
          modalPricingType={projectPricingType}
          bidAmount={formState}
          closeModal={closeModal} 
        />
      }
      {modalType === 'FEEDBACK_PENDING_BID' && 
        <InvestorFeedbackModal 
          bidStatus={InvestorFeedbackBidStatus.BID_PENDING} 
          closeModal={closeModal}
        />
      }
      {modalType === 'FEEDBACK_DECLINED_BID' && 
        <InvestorFeedbackModal 
          bidStatus={InvestorFeedbackBidStatus.BID_DECLINED} 
          closeModal={closeModal}
        />
      }
    </>
  );
};

export default function() {
  const { project } = useProjectContext();

  const defaults = {
    amount: null,
    unit: project?.pricingType === PricingType.OFFTAKE
    ? (project?.contractType === ContractType.PPA ? Unit.KWH : Unit.YEAR)
    : Unit.WDC,
    investorAcknowledgmentsFlag: false
  };

  return (
    <FormContextProvider defaults={defaults}>
      <BidForm />
    </FormContextProvider>
  );
}