import { useMutation } from 'react-query';
import AuthForm from '../components/AuthForm';
import { Button } from '~/components';
import Link from '~/components/Link';
import { resendConfirmationEmail } from '~/api/auth';
import toast from 'react-hot-toast';
import { useEffect, useRef, useState } from 'react';

export default function EmailVerification() {
  const [emailTimer, setEmailTimer] = useState(0);
  const emailTimerIntervalRef = useRef<NodeJS.Timer>();

  const { mutate: resendEmail, status } = useMutation(async () => {
    try {
      await resendConfirmationEmail();
      toast.success('Email confirmation link has been resent. Please check your spam folder if you do not see it in your inbox.', { duration: 6000 });
      let emailTimer = 30;
      setEmailTimer(emailTimer);
      emailTimerIntervalRef.current = setInterval(() => {
        setEmailTimer(--emailTimer);
        if (emailTimer <= 0) {
          clearInterval(emailTimerIntervalRef.current);
        }
      }, 1000);
    } catch (error) {
      toast.error('Failed to resend confirmation email. Please try again.', { duration: 4000 });
    }
  });

  // Ensure we clear the interval when the component unmounts
  useEffect(() => {
    return () => {
      if (emailTimerIntervalRef.current) {
        clearInterval(emailTimerIntervalRef.current);
      }
    };
  });

  return (
    <AuthForm header="E-mail confirmation required">
      <p>It looks like you haven’t confirmed your e-mail address yet. Please check your email inbox for a confirmation email from us and follow the instructions.</p> 
      <p>
        If you do not see an email from us, please check the spam folder or click the button below to re-send a confirmation link.
      </p>
      <p>
        Once you’ve confirmed your e-mail address, you’ll be able to log in. 
        If you need assistance or have any questions, please contact <Link as="a" href="mailto:support@conductor.solar">support@conductor.solar</Link>.
      </p>
      <Button 
        secondary
        size="large" 
        fluid
        loading={status === 'loading'}
        disabled={status === 'loading' || emailTimer > 0}
        onClick={() => resendEmail()}
      >
        {emailTimer > 0 ? `Resend in ${emailTimer}s` : 'Resend confirmation email'}
      </Button>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center', margin: 'var(--small) auto calc(-2 * var(--small))' }}>
        <Link to="/logout">
          Return to login
        </Link>
      </div>
    </AuthForm>
  );
}