import { useEffect } from 'react';
import { logout } from '~/api/auth';
import { usePromiseWatcher } from '~/hooks';
import { storage } from '~/utils';

export default function Logout () {
  const { execute: executeLogout } = usePromiseWatcher(
    async () => {
      let p;
      if (!storage.getSpoofedEmail()) {
        p = logout();
      } else {
        p = Promise.resolve();
      } try {
        return await p;
      } finally {
        storage.clear();
        setTimeout(() => {
          window.location.href = '/login';
        }, 1000);
      }
    }, {
      loading: 'Logging out...',
      success: 'Logged out successfully',
      error: 'Error occurred logging out. Please try again after the page reloads.'
    },
    []
  );

  useEffect(executeLogout, []);

  return <div />;
}