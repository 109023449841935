import { Portfolio } from '~/types/portfolio';
import { Project } from '~/types/project';
import ArchiveTimestamp from './ArchiveTimestamp';
import ReactivationTimestamp from './ReactivationTimestamp';
import LastUpdateTimestamp from './LastUpdateTimestamp';
import { usePageType } from '~/hooks';

export default function Timestamp({ 
  project, 
  portfolio 
}: { 
  project?: Project; 
  portfolio?: Portfolio 
}) {
  const pageType = usePageType();

  if (!project && !portfolio) {
    return null;
  }

  const reactivationTimestamp = project?.reactivatedProject?.createdAt ?? project?.portfolio?.reactivatedPortfolio?.createdAt ?? portfolio?.reactivatedPortfolio?.createdAt;
  const reactivationId = project?.reactivatedProject?.id ?? project?.portfolio?.reactivatedPortfolio?.id ?? portfolio?.reactivatedPortfolio?.id;
  let reactivationType = pageType;
  if (project?.portfolio?.reactivatedPortfolio) {
    reactivationType = 'portfolioProject';
  }

  let isArchived = project?.isArchived ?? portfolio?.isArchived;
  let archivedAt = project?.archivedAt ?? portfolio?.archivedAt;
  let archiveType = pageType;
  if (project?.portfolio?.isArchived) {
    isArchived = project.portfolio.isArchived;
    archivedAt = project.portfolio.archivedAt;
    archiveType = 'portfolioProject';
  }

  if (archivedAt && !(reactivationTimestamp && reactivationId)) {
    return <ArchiveTimestamp timestamp={archivedAt} type={archiveType} />;
  } else if (isArchived && reactivationTimestamp && reactivationId) {
    return  <ReactivationTimestamp id={reactivationId} timestamp={reactivationTimestamp} type={reactivationType} />;
  } else if (project?.updatedAt) {
    return <LastUpdateTimestamp timestamp={project.updatedAt} />;
  } else {
    return null;
  }
}