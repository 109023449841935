import * as S from './styles';
import { InvestorFeedbackSelectionOptions } from '~/constants/project-investor-feedback';
import { FormCheckboxGroup } from '~/components/form';

export default function BidFeedbackCheckbox() {
  return (
    <S.FeedbackCheckboxContainer>
      <FormCheckboxGroup
        schemaKey="feedbackSelection"
        values={InvestorFeedbackSelectionOptions}
      />
    </S.FeedbackCheckboxContainer>
  );
}

