import { Children, ReactNode } from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';
import Button from '../Button';

export default function AdditionalActions({
  trigger,
  children
}: {
  trigger?: ReactNode
  children?: ReactNode
}) {
  // If there are no children, return null
  if (!Children.toArray(children).some(Boolean)) {
    return null;
  }

  // Otherwise, render the dropdown
  return (
    <Dropdown
    direction='left'
      trigger={trigger ?? (
        <Button outlined>
          <Icon name='ellipsis horizontal' style={{ margin: 0 }} />
        </Button>
      )}
      closeOnBlur
      closeOnChange
      icon={false}
      
    >
      <Dropdown.Menu>
        {children}
      </Dropdown.Menu>
    </Dropdown>
  );
}