import { validation } from '~/schema/project';
import { createContext, useContext, useMemo } from 'react';
import { AutoSaveContextProvider, useAutoSaveContext } from '~/hooks/providers/AutoSaveContextProvider';
import { fetchProject, updateProject } from '~/api/projects';
import { getProjectState } from '~/utils';
import projectDisplayValues from '~/utils/displayValues/project';
import { calculateProjectCosts } from '~/utils/calculators';
import { useProjectConfig } from '~/requests/projects/useProjectConfig';
import { usePermissions } from '~/requests/permissions/usePermissions';
import { Project } from '~/types/project';
import { useFormConditions } from '../useFormConditions';
import formConfig from '~/schema/project/config';
import { useHistory, useLocation, useParams } from 'react-router-dom';

// 1. Create ProjectContext
const ProjectContext = createContext<{
  config: any;
  project: Project;
  formattedProject: Project;
  projectDisplayValues: any;
  updateProjectValue: any;
  projectErrors: any;
  handleOnChangeEvent: any;
  getProjectValue: any;
  fetchProject: any;
  fetchStatus: string;
  projectState: any;
  projectCosts: any;
  conditions: any;
  id: string;
}>({} as any);

export const useProjectContext = () => {
  // 2. Attempt to get the context
  const context = useContext(ProjectContext);

  // 3. Fall back to a blank context if we're not on a project page
  if (!context) {
    // Return blank stuff. We assume the developer knows what they're doing here.
    // Sometimes you might be on a project or portfolio page and want to be able to conditionally
    // use the result of either context hook without issues.
    return {
      project: {} as Project,
      config: {},
      formattedProject: {},
      projectDisplayValues: projectDisplayValues({}, {}, {}),
      updateProjectValue: () => {},
      projectErrors: {},
      handleOnChangeEvent: () => {},
      getProjectValue: () => {},
      fetchProject: () => {},
      fetchStatus: 'idle',
      projectState: {},
      projectCosts: {},
      conditions: {},
      id: '',
    };
  }

  return context;
};

// 4. Define ProjectContextProvider
export const ProjectContextProvider = ({ id, testValue, children }: any) => {
  const history = useHistory();
  const location = useLocation();
  const { id: projectId } = useParams() as any;

  return (
    <AutoSaveContextProvider
      {...{ id, testValue, validation }}
      label="project"
      api={{
        update: updateProject,
        fetch(id: any) {
          return fetchProject(id).then((data) => {
            if (
              data.redirectToProjectId &&
              projectId !== data.redirectToProjectId &&
              location.pathname.includes(`/project/${projectId}`)
            ) {
              const newPathname = location.pathname.replace(`/project/${projectId}`, `/project/${data.redirectToProjectId}`);
              history.replace(newPathname + location.search);
            }
            return data;
          });
        },
      }}
      config={formConfig}
    >
      <InnerProjectContextProvider>{children}</InnerProjectContextProvider>
    </AutoSaveContextProvider>
  );
};

// 5. Inner component for the ProjectContext logic
const InnerProjectContextProvider = ({ children }: any) => {
  const { permissions } = usePermissions();
  const { data: config } = useProjectConfig();
  const { state: rawState, updateValue, errors, handleOnChangeEvent, getValue, fetchFreshData, fetchStatus, id } = useAutoSaveContext() as any;

  const conditions = useFormConditions({
    data: rawState,
    config: formConfig,
    updateData: updateValue,
    getTransformedData: true,
  });

  const state = conditions?.transformedData;

  const projectState = useMemo(
    () => getProjectState(state, errors),
    [state, errors]
  );

  const projectCosts = useMemo(
    () =>
      calculateProjectCosts(state, {
        overall: true,
        perWdc: true,
        nrsFee: permissions?.hasProjectInvestorAccess,
      }),
    [state, permissions]
  );

  const displayValues = useMemo(
    () => projectDisplayValues(state, projectCosts, permissions),
    [state, projectCosts, permissions]
  );

  return (
    <ProjectContext.Provider
      value={{
        config,
        project: rawState as Project,
        formattedProject: state as Project,
        projectDisplayValues: displayValues,
        updateProjectValue: updateValue,
        projectErrors: errors,
        handleOnChangeEvent,
        getProjectValue: getValue,
        fetchProject: fetchFreshData,
        fetchStatus,
        projectState,
        projectCosts,
        conditions,
        id,
      }}
    >
      <div style={{ background: 'var(--color-off-white)' }}>
        {children}
      </div>
    </ProjectContext.Provider>
  );
};
