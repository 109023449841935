import { CSSProperties, useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import { updatePortfolioOwnership, updateProjectOwnership } from '~/api/admin';
import { usePageType, useProjectContext } from '~/hooks';
import { useAdminUsersQuery } from '~/requests/admin/useAdminUsersQuery';
import { ownershipKey, useOwnershipQuery } from '~/requests/ownership/useOwnershipQuery';

const textStyles = { 
  color: 'var(--color-charcoal)',
  fontWeight: 600, 
  fontSize: '0.875rem', 
  fontStyle: 'italic',
  letterSpacing: '0.07px',
  lineHeight: '1.25rem', 
};

export default function OwnershipPicker({ style = {} }: { style?: CSSProperties }) {
  const queryClient = useQueryClient();
  const pageType = usePageType();
  const { id } = useParams<{ id: string }>();
  const { project } = useProjectContext();
  const entityType = project?.portfolioId ? 'portfolio' : pageType;
  const entityId = project?.portfolioId ?? id;
  const typeDisplay = entityType.charAt(0).toUpperCase() + entityType.slice(1);

  const [saving, setSaving] = useState(false);

  const { data: ownershipData } = useOwnershipQuery();
  const { data: users } = useAdminUsersQuery();

  const owner = ownershipData?.owner;

  if (!owner || !users?.length) {
    return null;
  }

  return (
    <div style={style}>
      <span style={textStyles}>{typeDisplay} owner:{' '}</span>
      <Dropdown
        defaultValue={owner.id}
        disabled={saving}
        trigger={
          <button 
            style={{ 
              all: 'unset',
              textDecoration: 'underline',
              cursor: 'pointer',
              ...textStyles
            }}            
          >
            {owner.name}
          </button>
        }
        options={users.map((user) => ({
          key: user.id,
          text: user.name,
          value: user.id
        }))}
        onChange={async (e, { value: userId }) => {
          if (userId !== owner.id) {
            // Change to the new owner
            queryClient.setQueryData(ownershipKey(entityType, entityId), {
              ...ownershipData,
              owner: users.find((user) => user.id === userId),
              ownerId: userId
            });
            try {
              setSaving(true);
              if (entityType === 'project') {
                await updateProjectOwnership(entityId, userId as string);
              } else if (entityType === 'portfolio') {
                await updatePortfolioOwnership(entityId, userId as string);
              } else {
                throw new Error('Invalid page type');
              }
            } catch (error) {
              // Reset the owner
              queryClient.setQueryData(ownershipKey(entityType, entityId), {
                ...ownershipData,
                owner,
                ownerId: owner.id
              });

              // Throw a toast message
              toast.error('Failed to update the owner', { duration: 5000 });
            } finally {
              setSaving(false);
            }
          }
        }}
      />
    </div>
  );
}