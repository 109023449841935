export const ProjectStatus = {
  DRAFT: 'DRAFT', // Project is not yet submitted, or has been rejected by the admin. Not in review -- only the installer is acting on it.
  IN_REVIEW: 'IN_REVIEW', // Project submitted to Conductor Solar, but never assigned to an investor.
  MATCHING: 'MATCHING', // A project has been assigned to an investor, but no bid has been accepted.
  BID_REVIEW: 'BID REVIEW', //Date has passed for project bid deadline
  IN_DILIGENCE: 'IN_DILIGENCE', //  A bid has been accepted. The data room may not be set up yet, but that’s the next thing to do in the app.
  COMPLETED: 'COMPLETED', // The project has finished diligence phase and is now in the “completed” phase
  DECLINED_TO_BID: 'DECLINED_TO_BID', // Investor declined to bid.
  BID_REJECTED: 'BID_REJECTED' // A different bid was accepted.
};

export const ProjectStatusDisplay = {
  [ProjectStatus.DRAFT]: 'Not Submitted',
  [ProjectStatus.IN_REVIEW]: 'In Review',
  [ProjectStatus.MATCHING]: 'In Review',
  [ProjectStatus.BID_REVIEW]: 'Bid Review',
  [ProjectStatus.IN_DILIGENCE]: 'In Diligence',
  [ProjectStatus.COMPLETED]: 'Deal Completed',
  [ProjectStatus.DECLINED_TO_BID]: 'Declined to Bid',
  [ProjectStatus.BID_REJECTED]: 'Bid Not Selected'
};

export const DocumentStatus = {
  DRAFT: 'DRAFT',
  FINAL: 'FINAL',
};

