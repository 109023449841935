import { ProjectStatus, ProjectStatusDisplay } from '~/constants';
import { parseISO, isAfter, isSameDay } from 'date-fns';
import { Project } from '~/types/project';


export default function status(project: Project, permissions: any) {
  const status = project?.status;
  const currentDate = new Date();
  const bidDeadlineData = project?.bidDeadline || project?.portfolio?.bidDeadline as string;
  const bidDeadline = parseISO(bidDeadlineData);
  const isBidDeadlinePassed = isAfter(currentDate, bidDeadline) || isSameDay(currentDate, bidDeadline);
  const isInvestor = !permissions.isAdmin && permissions.hasProjectInvestorAccess;

  if (project?.reactivatedProject) {
    return 'Reactivated';
  }
  
  if (project?.isArchived || project?.portfolio?.isArchived) {
    return 'Archived';
  }

  if (status === ProjectStatus.MATCHING && isInvestor) {
    // Investor view of standalone or portfolio project
    if (isBidDeadlinePassed) {
    // If bid deadline has passed or bid deadline is current date
      return 'Bid Review';
    } else {
      return 'Bidding';
    }
  }

  if (status === ProjectStatus.MATCHING) {
    if (!project?.bidSummary?.length) {
      // Project is still in review by admins, no investors matched yet
      return 'In Review';
    } else if (isBidDeadlinePassed) {
      // If bid deadline has passed or bid deadline is current date
      return 'Bid Review';
    } else {
      // Investors have been matched, project is open for bidding
      return 'Bidding';
    }
  }

  return ProjectStatusDisplay[status];
}