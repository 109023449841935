import { useEffect, useState } from 'react';
import { FormSchemaAwareWidget } from '~/components/form';
import { Button, Checkbox } from 'semantic-ui-react';
import { usePromiseWatcher, useForm } from '~/hooks';
import { fetchRoles, signup } from '~/api/user';
import { ErrorTexts, UserRole } from '~/constants';
import { FormContextProvider } from '~/hooks/providers/FormContextProvider';
import AuthForm from '../components/AuthForm';
import Link from '~/components/Link';
import toast from 'react-hot-toast';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;

interface UserSignupProps {
  userType: any;
  inviteToken: any;
  presetEmail: string;
  defaults?: any;
  externalCompanyId?: string;
}

const UserSignupImpl = ({ userType, inviteToken, presetEmail, externalCompanyId }: UserSignupProps) => {
  const [agreed, setAgreed] = useState(false);
  const [showErrors, setShowErrors] = useState<undefined | true>(undefined);
  const [emailExistsError, setEmailExistsError] = useState('');
  const [emailExistMap, setEmailExistMap] = useState<{ [key: string]: true }>({});
  const {formState, updateFormValue} = useForm() as any;

  const needsCompanySignup = !inviteToken || (inviteToken && userType === UserRole.EXTERNAL && !externalCompanyId); // TODO add check here to look for external company role code + null externalCompanyId

  // Set up form validation states
  const requiredFieldErrors = {
    name: !formState.name ? 'Required' : '',
    company: needsCompanySignup && !formState.company ? 'Required' : '',
    email: !formState.email ? 'Required' : '',
    password: !formState.password ? 'Required' : '',
    passwordConfirm: !formState.passwordConfirm ? 'Required' : ''
  };
  const emailError = !emailRegex.test(formState.email) ? 'Invalid email' : '';
  const passwordLengthError = formState.password && formState.password.length < 8 ? 'Password must be at least 8 characters' : '';
  const passwordMatchError = formState.passwordConfirm && formState.passwordConfirm !== formState.password ? 'Passwords do not match' : '';

  const valid = (
    !Object.values(requiredFieldErrors).some((error) => !!error)
    && !emailExistsError
    && !emailError
    && !passwordLengthError
    && !passwordMatchError
  );

  const { execute, status } = usePromiseWatcher(
    () => {
      return signup({ 
        ...formState, 
        inviteToken 
      })
      .then(() => {
        // Redirect to home page after successful signup.
        // Signup endpoint should also log the user in.
        window.location.href = '/confirm-email';
      });
    }, {
      messageStub: 'signing you up',
      duration: 5000,
      error: (e: any) => {
        if (e.message === ErrorTexts.USER_ALREADY_EXISTS) {
          setEmailExistMap({ ...emailExistMap, [formState.email]: true });
          setShowErrors(true);
          return (
            <div>
              There is an existing account associated with this e-mail address.{' '}
              Please enter another e-mail, or if you believe this to be in error, contact us via email at{' '}
              <a href="mailto:support@conductor.solar">support@conductor.solar</a>
            </div>
          );
        }
      }
    },
    [formState, inviteToken],
    {toastOpts: { id: 'signup', duration: 6500 }} as any
  );

  const handleSignup = () => {
    if (valid) {
      execute();
    } else {
      toast.error('Please fix your errors and try again.', { duration: 3000 });
      setShowErrors(true);
    }
  };

  if (presetEmail && presetEmail !== formState.email) {
    updateFormValue('email', presetEmail);
  }

  const { execute: getRoles } = usePromiseWatcher(
    async () => {
      const roles = await fetchRoles();
      const role = Object.values(roles).find((role: any) => role.code === userType) as any;
      updateFormValue('roleId', role.id);
    },
    'fetching your role id',
    [userType]
  );
  useEffect(() => {
    if (needsCompanySignup) {
      getRoles();
    }
  }, []);

  useEffect(() => {
    if (emailExistMap[formState.email]) {
      setEmailExistsError('E-mail already in use');
    } else {
      setEmailExistsError('');
    }
  }, [formState.email, emailExistMap]);

  let headerText = 'New User Signup';
  if (userType === UserRole.INSTALLER) {
    headerText += ' — Developer / EPC';
  } else if (userType === UserRole.INVESTOR) {
    headerText += ' — Investor';
  }

  return (
    <>
      <AuthForm width="32rem" header={headerText}>
        <FormSchemaAwareWidget
          schemaKey="name"
          fluid
          placeholder="Full name"
          errorText={showErrors && requiredFieldErrors.name}
        />
        {needsCompanySignup && (
          <FormSchemaAwareWidget
            schemaKey="company"
            fluid
            placeholder="Company name"
            errorText={showErrors && requiredFieldErrors.company}
          />
        )}
        <FormSchemaAwareWidget
          schemaKey="email"
          fluid
          placeholder="Email"
          autoComplete="username"
          type="email"
          readOnly={!!presetEmail}
          errorText={showErrors && (requiredFieldErrors.email || emailError || emailExistsError)}
        />
        <FormSchemaAwareWidget
          schemaKey="password"
          fluid
          placeholder="Password"
          autoComplete="new-password"
          type="password"
          errorText={showErrors && (requiredFieldErrors.password || passwordLengthError)}
        />
        <FormSchemaAwareWidget
          schemaKey="passwordConfirm"
          fluid
          placeholder="Repeat Password"
          autoComplete="new-password"
          type="password"
          errorText={showErrors && (requiredFieldErrors.passwordConfirm || passwordMatchError)}
        />
        <Checkbox
          onChange={() => setAgreed(!agreed)}
          checked={agreed}
          label={
            <label>
              I agree to the {' '}
              <Link as="a" href="https://conductor.solar/terms-of-service" bold>
                Terms of Service
              </Link>{' '}
              and{' '}
              <Link as="a" href="https://conductor.solar/privacy-policy" bold>
                Privacy Policy
              </Link>
              .
            </label>
          }
        />
        <Button
          disabled={!agreed}
          loading={status === 'pending'}
          onClick={handleSignup}
          fluid 
          secondary
          size="large" 
        >
          Create Account
        </Button>
      </AuthForm>
    </>
  );
};

const UserSignup = ({userType, inviteToken, presetEmail, externalCompanyId, defaults = {
  name: '',
  company: '',
  email: presetEmail ?? '',
  password: '',
  passwordConfirm: '',
  roleName: userType,
  roleId: ''
}}: UserSignupProps) => {
  return (
    <FormContextProvider defaults={defaults}>
      <UserSignupImpl  {...{ userType, inviteToken, presetEmail, externalCompanyId }}/>
    </FormContextProvider>
  );
};

export default UserSignup;
