import { format } from 'date-fns';
import { TimestampStyles } from './styles';

export default function ArchiveTimestamp({ 
  timestamp,
  type
}: { 
  timestamp: string,
  type: string
}) {
  return (
    <TimestampStyles>
      {type === 'portfolioProject' ? 'Portfolio archived' : 'Archived'} on: {format(new Date(timestamp), 'EEEE, MMMM d, yyyy, hh:mm a')}
    </TimestampStyles>
  );
}