import { DeploymentTypes } from '~/constants/deployment-type';
import { REC_DATA_STATES } from '~/features/projectWizard/derived';
import { Project } from '~/types/project';

interface RecValueAssumptionsProps {
  project: Project;
}

export default function RecValueAssumptions({ project }: RecValueAssumptionsProps) {
  return (
    project.deploymentType !== DeploymentTypes.COMMUNITY_SOLAR 
      && !REC_DATA_STATES.includes(project?.addressState as string)
      && <p
          style={{ 
            color: 'var(--color-primary)', 
            fontWeight: '700',
            marginTop: project.recOwnership ? 'var(--2x-small)' : 'var(--large)'
          }}
        >
          Please note that Conductor is making its own assumptions on the value of RECs in {project.addressState} for pricing estimation purposes on the Summary page.
        </p>
  );
}