export type ProjectFilterType = '' | 'DRAFT' | 'IN_REVIEW' | 'BIDDING' | 'BID_REVIEW' | 'IN_DILIGENCE' | 'CLOSED' | 'OPEN_ITEMS';

export const ProjectFilters = {
  ALL: '',
  DRAFT: 'DRAFT',
  IN_REVIEW: 'IN_REVIEW',
  BID_REVIEW: 'BID REVIEW',
  BIDDING: 'BIDDING',
  IN_DILIGENCE: 'IN_DILIGENCE',
  COMPLETED: 'COMPLETED',
  CLOSED: 'CLOSED',
  OPEN_ITEMS: 'OPEN_ITEMS',
  CUSTOMER_PROPOSALS: 'CUSTOMER_PROPOSALS',
};

export const ProjectFiltersList = [
  { value: ProjectFilters.ALL, label: 'All projects' },
  { value: ProjectFilters.DRAFT, label: 'Draft', hideForInvestors: true },
  { value: ProjectFilters.IN_REVIEW, label: 'In review', hideForInvestors: true },
  { value: ProjectFilters.BIDDING, label: 'Bidding' },
  { value: ProjectFilters.BID_REVIEW, label: 'Bid Review' },
  { value: ProjectFilters.IN_DILIGENCE, label: 'In diligence' },
  { value: ProjectFilters.COMPLETED, label: 'Completed'},
  { value: ProjectFilters.CLOSED, label: 'Archived' },
  { value: ProjectFilters.OPEN_ITEMS, label: 'Open Items', hideForInvestors: true, hideForDevelopers: true }
];