import { TeamMember } from '~/types/teamManagement';
import { Table, Modal, Button } from '~/components';
import { usePermissions } from '~/requests/permissions/usePermissions';
import { useUpdateProjectTeamMemberMutation } from '~/mutations/team';
import { SyntheticEvent, useState } from 'react';
import { DropdownProps } from 'semantic-ui-react';
import { ProjectTeamRolesDisplay, ProjectTeamRolesDropdownOptions } from '~/constants/project-team-role';
import { usePageType, useProjectContext, useUserContext } from '~/hooks';
import { useProjectTeamQuery } from '~/requests/team';
import toast from 'react-hot-toast';
import { PortfolioTeamRolesDisplay, PortfolioTeamRolesDropdownOptions } from '~/constants/portfolio-team-role';
import useArchiveToast from '~/hooks/useArchiveToast';

const RoleDropdownTableCell = ({ member }: { member: TeamMember }) => {
  const pageType = usePageType();
  const { project } = useProjectContext();
  const { permissions, refetch: refetchPermissions } = usePermissions();
  const { loggedInUser } = useUserContext() as any;
  const { team } = useProjectTeamQuery();
  const { mutateAsync, status } = useUpdateProjectTeamMemberMutation();
  const [role, setRole] = useState(member.role);
  const isSelf = member.userId === loggedInUser.id;
  const [desiredSelfRole, setDesiredSelfRole] = useState<null | 'LEAD' | 'MEMBER'>(null);
  const { showArchiveToast } = useArchiveToast();

  // Get constant to use based on pageType.
  // This differentiates between project and portfolio verbiage e.g. 'Project lead' vs 'Portfolio lead'
  const isProject = pageType === 'project' && !project?.portfolioId;
  const TeamRolesDisplay = isProject ? ProjectTeamRolesDisplay : PortfolioTeamRolesDisplay;
  const TeamRolesDropdownOptions = isProject ? ProjectTeamRolesDropdownOptions : PortfolioTeamRolesDropdownOptions;

  // Handle the role change
  const handleChange = (_e: SyntheticEvent, data: DropdownProps) => {
    // Abort if archived
    if (showArchiveToast()) {
      return;
    }

    // Get the new role code from the dropdown
    const role = data.value as 'LEAD' | 'MEMBER';
    const hasOtherProjectLead = !team?.some((otherMember) => otherMember.role === 'LEAD' && otherMember.isActive && !!otherMember.userId && otherMember.userId !== member.userId);
    if (hasOtherProjectLead) {
      // Throw an error toast if they are trying to remove themselves as the last lead
      toast.error(`You cannot remove the last lead of the ${pageType}. Please set another user as ${pageType} lead and try again.`, { duration: 8000 });
    } else if (isSelf && !permissions.isProjectTeamCompanyAdmin) {
      setDesiredSelfRole(role);
    } else {
      // Update the user's role in the project/portfolio team
      setRole(role); // Change the dropdown value to the new role
      mutateAsync({ 
        memberId: member.id as string, 
        role 
      }).catch(() => {
        setRole(member.role); // Revert the role to the previous value if an error occurs
      });
    }
  };

  if (!member?.isActive) {
    return (
      <Table.Cell textAlign="center">—</Table.Cell>
    );
  }

  if (member.isExternal) {
    return (
      <Table.Cell textAlign={permissions.canChangeTeamRole ? 'right' : 'center'}>
        {TeamRolesDisplay.MEMBER} - External
      </Table.Cell>
    );
  }

  if (!member.id || !permissions?.canChangeTeamRole) {
    return (
      <Table.Cell textAlign="center">
        {member?.role
          ? TeamRolesDisplay[member.role] ?? '—'
          : '—'}
      </Table.Cell>
    );
  }

  return (
    <>
      <Table.CellDropdown
        value={role}
        options={TeamRolesDropdownOptions}
        onChange={handleChange}
        disabled={status === 'loading'}
        style={{ fontWeight: 'normal' }}
      />
      {desiredSelfRole && (
        <Modal closeModal={() => setDesiredSelfRole(null)}>
          <Modal.Title>Changing your privileges</Modal.Title>
          <Modal.Content>
            <p>By changing your team role from Lead, you will lose the ability to set {pageType} roles. Additionally, you will be unable to re-assign yourself the Lead role without assistance.</p>
          </Modal.Content>
          <Modal.Footer>
            <Button
              primary
              outlined
              onClick={() => setDesiredSelfRole(null)}
            >
              Cancel
            </Button>
            <Button
              primary
              disabled={status === 'loading'}
              loading={status === 'loading'}
              onClick={async () => {
                await mutateAsync({ memberId: member.id as string, role: desiredSelfRole });
                await refetchPermissions();
                setDesiredSelfRole(null);
                toast.success('You successfully changed your own role.');
              }}
            >
              I understand
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default RoleDropdownTableCell;