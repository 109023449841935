import { FormRadioSelectGroup } from '~/components/form';
import { InvestorFeedbackRatingRadioOptions } from '~/constants/project-investor-feedback';

export default function BidFeedbackRatingRadio() {
  return (
    <FormRadioSelectGroup
      schemaKey="feedbackRating"
      fieldLabel="How well does this opportunity fit for your project targets?"
      values={InvestorFeedbackRatingRadioOptions}
      inline
      stretched
    />
  );
}