import { useState } from 'react';
import * as S from './styles';
import { usePortfolioContext, useProjectContext } from '~/hooks';
import UpdateProjectNameModal from '~/modals/UpdateProjectNameModal';
import { Icon } from 'semantic-ui-react';
import UpdatePortfolioNameModal from '~/modals/UpdatePortfolioNameModal';
import { Button } from '~/components';
import { Link } from 'react-router-dom';
import { usePermissions } from '~/requests/permissions/usePermissions';

interface Props {
  type: 'project' | 'portfolio' | 'portfolioProject'
  allowEdits?: boolean
  editButtonDisabled?: boolean
}

export default function EditableName({ type, editButtonDisabled = false, allowEdits = true }: Props) {
  // Get project/portfolio from context
  const { project, projectDisplayValues }  = useProjectContext();
  const { portfolio, portfolioDisplayValues } = usePortfolioContext();
  const { permissions } = usePermissions();

  // Determine if data is loaded
  const dataLoaded = !!project?.id || !!portfolio?.id;

  // Track modal visibility
  const [showModal, setShowModal] = useState(false);

  // Derive booleans based on type prop
  const useSecondaryColor = type === 'portfolio';
  const isProject = type === 'project' || type === 'portfolioProject';
  const isPortfolio = type === 'portfolio';

  // Derive name and status: 'in review', 'bidding', 'bid review'
  const name = isProject ? project?.name : portfolio?.name;
  const status = isProject ? projectDisplayValues.status : portfolioDisplayValues.status;

  const isArchived = isProject ? project?.isArchived || project?.portfolio?.isArchived : portfolio?.isArchived;

  return (
    <S.EditableNameContainer 
      className="editable-name" 
      useSecondaryColor={useSecondaryColor}
    >
      {!!project?.portfolio?.id && (
        <Button
          size="small"
          as={Link} 
          to={`/portfolio/${project?.portfolio?.id}`}
          style={{ display: 'inline-flex', alignItems: 'center' }}
        >
          <Icon name="arrow left" style={{ transform: 'translateY(-1px)' }} />
          <div>Portfolio</div>
        </Button>
      )}
      {dataLoaded && (
        <h1 style={{ color: isArchived ? 'var(--color-charcoal)' : 'var(--color-primary)' }}>
          {name}
          {status && <em> — {status}</em>}
        </h1>
      )}
      {allowEdits && permissions.hasProjectDeveloperAccess && (
        <S.EditableNameButton className="edit" disabled={editButtonDisabled} size="tiny" aria-label={`Edit ${type}`} onClick={() => setShowModal(true)}>
          <Icon name="pencil" />
        </S.EditableNameButton>
      )}

      {/* Modals */}
      {showModal && isProject && <UpdateProjectNameModal closeModal={() => setShowModal(false)} />}
      {showModal && isPortfolio && <UpdatePortfolioNameModal closeModal={(() => setShowModal(false))} />}
    </S.EditableNameContainer>
  );
}