import toast from 'react-hot-toast';
import { useMutation, MutationOptions } from 'react-query';
import { saveInvestorFeedback } from '~/api/projectBids';
import { useProjectContext } from '~/hooks';


export const useSaveInvestorFeedback = (options: MutationOptions = {}) => {
  const { onSuccess } = options;
  const { project, id } = useProjectContext();
  const investorId = project?.investorId;

  return useMutation(
    {
      mutationFn: async (formState: any) => {
        const result = await saveInvestorFeedback(id, { ...formState, investorId });
        return result;
      },
      onSuccess: (data, variables, context) => {
        toast.success('Thank you for submitting your bid and sharing your feedback.', { duration: 5000 });
        onSuccess?.(data, variables, context);
      },
      onError: (error) => {
        console.error(error);
        toast.error(
          <div>
            <p>
              We ran into an error while submitting your feedback. Please try again, and if you continue to run into issues, contact{' '}
              <a href="mailto:support@conductor.solar">support@conductor.solar</a>.
            </p>
          </div>,
          { duration: 5000 }
        );
      }
    }
  );
};

