import { useEffect } from 'react';
import { useMutation } from 'react-query';
import AuthForm from '../components/AuthForm';
import { Loader } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { confirmEmail } from '~/api/auth';

export default function RunEmailVerification() {
  const { token } = useParams<{ token: string }>();

  const { mutate, status } = useMutation(() => confirmEmail(token));
  useEffect(() => {
    if (token) {
      mutate();
    }
  }, [token]);

  return (
    <AuthForm header={status === 'error' ? 'An error occured' : 'Confirming your email'}>
      {status === 'error' ? (
        <>
          <p>There was an error confirming your email address. Please refresh the page to try again.</p>
          <p>If this problem continues, please contact us at <a href="mailto:support@conductor.solar">support@conductor.solar</a></p>
        </>
      ): (
        <Loader active inline="centered" />
      )}
    </AuthForm>
  );
}