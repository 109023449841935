import { FormSchemaAwareWidget } from '~/components/form';
import { TextArea } from 'semantic-ui-react';
import { InvestorFeedbackSelection } from '~/constants/project-investor-feedback';
import { useForm } from '~/hooks';

export default function BidFeedbackNotes() {
  const { formState } = useForm() as any;
  
  const errorText = formState?.feedbackSelection.includes(InvestorFeedbackSelection.OTHER) && !formState.feedbackNotes ? 'Additional information is required for this selection.' : '';
  const placeholderText = 'Please let us know any other factors that make this opportunity a less-than-great fit for your organization.';
  
  return (
    <FormSchemaAwareWidget
      schemaKey='feedbackNotes'
      as={TextArea}
      placeholder={placeholderText}
      errorText={errorText}
    />
  );
}

