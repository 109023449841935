import { useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { useMutation } from 'react-query';
import { toast } from 'react-hot-toast';
import { useDropzone } from 'react-dropzone';
import { useConversationListQuery } from '~/requests/conversations/useConversationListQuery';
import { useUploadFileMessageMutation } from '~/mutations/conversations/useUploadFileMessageMutation';
import { Conversation } from '~/types/conversations/Conversation';
import { updateProjectCustomerProposal } from '~/api/admin';
import { useMessagingContext } from '../features/messaging/providers/MessagingProvider';
import { useProjectContext } from '~/hooks';
import CustomerProposalModal from '~/modals/CustomerProposalModal';
import { usePermissions } from '~/requests/permissions/usePermissions';
import { useDownloadMessageFileQuery } from '~/requests/conversations/useDownloadMessageFileQuery';

interface UpdateProposalRequestParams {
  id: string;
  messageId: string | undefined; 
}

export default function ProjectCustomerProposal() {
  const { project, id, updateProjectValue } = useProjectContext();
  const { data: conversations } = useConversationListQuery();
  const supportConversationId = conversations?.find((conversation: Conversation) => conversation.type === 'SUPPORT' && !!conversation.installerId)?.id as string;
  const { addNewMessage } = useMessagingContext();
  const { permissions } = usePermissions();
  const [modalOpen, setModalOpen] = useState(false);

  const { mutate: updateProposalRequest, status: updateProposalRequestStatus } = useMutation(
    async ({ id, messageId }: UpdateProposalRequestParams) => {
      try {
        if (id && messageId) {
          const result = await updateProjectCustomerProposal(id, messageId);
          await updateProjectValue('customerProposal', result, { doSave: false, patch: true }); 
        }
      } catch (error) {
        console.error(error);
        toast.error('Error updating proposal.', { duration: 5000 });
      }
    },
  );
  
  const uploadFileMessage = useUploadFileMessageMutation();

  const { getInputProps, open } = useDropzone({   
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
    async onDrop(acceptedFiles) {
      try {
        if (acceptedFiles?.length) {
          const message = await uploadFileMessage.mutateAsync({
            file: acceptedFiles?.[0],
            conversationId: supportConversationId
          });
          addNewMessage(message);
          const messageId = message?.id;
          await updateProposalRequest({ id, messageId });
          toast.success('Proposal uploaded successfully.', { duration: 5000 });
        } else {
          toast.error('Only one file can be uploaded at a time.');
        }
      } catch (error) {
        console.error(error);
        toast.error('Error uploading file.', { duration: 5000 });
      }
    }
  });

  const { refetch: downloadProposalFile, status: downloadProposalFileStatus } = useDownloadMessageFileQuery({
    conversationId: supportConversationId,
    messageId: project?.customerProposal?.messageId as string
  });

  if (!project?.customerProposal) {
    return (
      <>
        <Dropdown.Item
          text={'Request customer proposal'}
          onClick={() => setModalOpen(true)}
        />
        {modalOpen && <CustomerProposalModal closeModal={() => setModalOpen(false)} />}
      </>
    );
  }

  if (project?.customerProposal?.requestedAt && !project?.customerProposal?.messageId) {
    return (
      <>
        <input {...getInputProps()} />
        <Dropdown.Item
          text={'Awaiting customer proposal'}
          onClick={() => open()}
          loading={updateProposalRequestStatus === 'loading'}
          disabled={updateProposalRequestStatus === 'loading' || (permissions.hasProjectDeveloperAccess && !permissions.isAdmin)}
        />
     </>
    );
  }

  if (project?.customerProposal?.uploadedAt && project?.customerProposal?.messageId) {
    return (
      <Dropdown.Item
        text={'View customer proposal'}
        onClick={() => downloadProposalFile()}
        loading={downloadProposalFileStatus === 'loading'}
        disabled={downloadProposalFileStatus === 'loading'}
      />
    );
  }

  return null;
}