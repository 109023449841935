import { Modal } from '~/components';
import { Button } from '~/components';
import { useMutation } from 'react-query';
import { useProjectContext } from '~/hooks';
import { createCustomerProposalRequest } from '~/api/projects';
import { toast } from 'react-hot-toast';

export default function CustomerProposalModal({ closeModal }: { closeModal: Function }) {
  const { project, updateProjectValue } = useProjectContext();

  const { mutate: submitProposalRequest, status: submitProposalRequestStatus } = useMutation(
    async () => {
      try {
        const result = await createCustomerProposalRequest(project?.id);
        updateProjectValue('', result, { doSave: false, patch: true });
        toast.success('Customer proposal requested.', { duration: 5000 });
        closeModal();
      } catch (error) {
        console.error(error);
        toast.error('Error requesting customer proposal.', { duration: 5000 });
      }
    }
  );
  
  return (
    <Modal>
      <Modal.Title>
        Confirm proposal request
      </Modal.Title>
      <Modal.Content>
        <p>
          This project and its information will be provided to the Conductor team and will be used to generate a customer-facing proposal. Once generated, the proposal will be uploaded to the project and accessible from your Conductor Support messaging thread.
        </p>
      </Modal.Content>
      <Modal.Footer>
        <Button primary outlined onClick={() => closeModal()}>
          Cancel
        </Button>
        <Button 
          primary 
          onClick={() => submitProposalRequest()}
          loading={submitProposalRequestStatus === 'loading'}
          disabled={submitProposalRequestStatus === 'loading'}
        >
          Submit my request
        </Button>
      </Modal.Footer>
    </Modal>

  );
}