import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from '~/components/Modal';
import { useModalContext } from '~/hooks/providers/ModalProvider';
import { Project } from '~/types/project';
import DuplicateProjectForm from './DuplicateProjectForm';
import CreateProjectForm from './CreateProjectForm';
import { withForm } from '~/utils/withForm';
import styled from 'styled-components';

const ModalWrapper = styled.div`
  i.icon.close {
    background-color: red !important;
  }
`;

const CreateProjectModal = withForm({ defaults: { name: '', addressState: '' } }, () => {
  const [isDuplicatingProject, setIsDuplicatingProject] = useState(false);
  const history = useHistory();
  const { closeModal } = useModalContext();
  const modalRef = useRef<HTMLDivElement>(null);

  // Get the form component and props to send to the form,
  // based on whether we are showing the create project or duplicate project form
  const FormComponent = isDuplicatingProject ? DuplicateProjectForm : CreateProjectForm;
  const formProps = {
    modalRef,
    onSuccess(project: Project) {
      const id = project?.id;
      if (id) {
        history.push(`/project/${id}/wizard`);
        closeModal();
      }
    },
    toggleFormType() {
      setIsDuplicatingProject((prev) => !prev);
    }
  };

  // Render the FormComponent, which will be either the create project or duplicate project form
  return (
    <ModalWrapper>
      <Modal ref={modalRef}>
        <FormComponent {...formProps} />
      </Modal>
    </ModalWrapper>
  );
});

export default CreateProjectModal;