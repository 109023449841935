import * as S from './style';
import { usePortfolioContext } from '~/hooks';
import ProjectSummaryData from '../projectReview/components/ProjectSummaryData';
import PortfolioProjectTitle from './components/PortfolioProjectTitle';
import PortfolioTheme from '~/components/PortfolioTheme';
import PortfolioSummaryTable from './components/PortfolioSummaryTable';
import { DealStates } from '~/constants';
import DealSummary from './components/DealSummary';
import AdminPortfolioInvestorInvites from '../admin/AdminPortfolioInvestorInvites';
import { usePermissions } from '~/requests/permissions/usePermissions';
import OwnershipPicker from '~/components/OwnershipPicker';
import Timestamp from '~/components/Timestamp';

export default function PortfolioReview() {
  const { portfolio, displayBlobs: projectBlobs, portfolioState } = usePortfolioContext();
  const { permissions } = usePermissions();

  const isInvestor = !permissions.isAdmin && permissions.hasPortfolioInvestorAccess;

  const showPortfolioSummary = isInvestor || portfolioState?.latestState < DealStates.AWAITING_BID || portfolioState?.latestState >= DealStates.IN_DILIGENCE;
  const showDealSummary = !isInvestor && (portfolioState?.latestState >= DealStates.IN_REVIEW && portfolioState?.latestState < DealStates.IN_DILIGENCE);

  return (
    <div style={{ paddingBottom: 'var(--x-large)' }}>
      <PortfolioTheme>
        <div style={{ display: 'flex', alignItems: 'center', paddingTop: 'var(--x-small)' }}>
          {permissions.isAdmin && <OwnershipPicker style={{ marginRight: 'var(--small)' }} />}
          <div><Timestamp portfolio={portfolio} /></div>
        </div>
        {showPortfolioSummary && (
          <S.SummaryTableContainer>
            <PortfolioSummaryTable />
          </S.SummaryTableContainer>
        )}
        {permissions.isAdmin && <AdminPortfolioInvestorInvites />} 
        {showDealSummary && (
          <S.DealSummaryContainer>
            <DealSummary />
          </S.DealSummaryContainer>
        )}
        {projectBlobs?.map((projectBlob: any) => (
          <S.ProjectContainer key={projectBlob?.project?.id}>
            <PortfolioProjectTitle projectBlob={projectBlob} />
            <ProjectSummaryData project={projectBlob?.project} values={projectBlob?.projectDisplayValues} projectState={projectBlob?.projectState} projectErrors={projectBlob?.projectErrors} />
          </S.ProjectContainer>
        ))}
      </PortfolioTheme>
    </div>
  );
}

