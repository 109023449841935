import { useMutation } from 'react-query';
import { Form } from 'semantic-ui-react';
import { createProject } from '~/api/projects';
import { Button, Modal } from '~/components';
import { FormSchemaAwareWidget } from '~/components/form';
import Link from '~/components/Link';
import { useForm } from '~/hooks';
import { useModalContext } from '~/hooks/providers/ModalProvider';
import { defaultValues } from '~/schema/project';
import { STATES, withPromiseToaster } from '~/utils';
import { ModalFormProps } from './types';
import { useProjectList } from '~/requests/projects/useProjectList';

export default function CreateProjectForm({ toggleFormType, onSuccess }: ModalFormProps) {
  const { closeModal, modalProps } = useModalContext();
  const portfolioId = modalProps?.portfolioId;
  const { data: projects } = useProjectList();

  const { formState } = useForm() as any;

  // Determine if form has an error
  const hasError = !formState.name || !formState.addressState;

  const { mutate: handleSubmit, status } = useMutation(() => withPromiseToaster(
    createProject({ 
      ...defaultValues, 
      ...formState,
      portfolioId 
    }).then(onSuccess),
    { messageStub: 'creating the project' + (portfolioId ? ' and adding it to the portfolio' : '')}
  ));

  return (
    <div>
      <Modal.Title>
        Create a new project
      </Modal.Title>
      <Modal.Content>
        <p>
          Start with the basics so we can customize your project information based on location.
        </p>
        <Form onSubmit={() => handleSubmit()}>
          <FormSchemaAwareWidget
            fieldLabel="Project name"
            placeholder="Enter value"
            schemaKey="name"
          />
          <FormSchemaAwareWidget
            fieldLabel="State"
            placeholder="Enter value"
            schemaKey="addressState"
            search
            options={STATES}
          />
        </Form>
      </Modal.Content>
      <Modal.Footer>
        {!!projects?.length && (
          <Link 
            as={Button} 
            style={{ marginRight: 'auto' }}
            onClick={toggleFormType}
          >
            Duplicate a project
          </Link>
        )}
        <Button primary outlined onClick={closeModal}>Cancel</Button>
        <Button
          primary
          disabled={hasError || status === 'loading'}
          loading={status === 'loading'}
          onClick={() => handleSubmit()}
        >
          Create project
        </Button>
      </Modal.Footer>
    </div>
  );
}