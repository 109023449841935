import { CSSProperties, forwardRef, ForwardRefExoticComponent, HTMLProps, RefAttributes } from 'react';
import { useModalContext } from '~/hooks/providers/ModalProvider';
import { useState } from 'react';
import * as S from './styles';
import ReactModal from 'react-modal';

export type ModalProps = HTMLProps<HTMLDivElement> & Partial<ReactModal.Props> & {
  closeModal?: Function
  onOpen?: Function
  onClose?: Function
  allowClose?: boolean
  width?: string
  contentStyles?: CSSProperties
  ref?: any
}

const Modal = forwardRef(({
  children,
  onOpen = () => {},
  onClose = () => {},
  closeModal,
  allowClose = true,
  width = '34.5rem',
  contentStyles = {},
  ...props
}: ModalProps, ref) => {
  const modalContext = useModalContext();

  // Set up default closeModal function if it's not passed in
  closeModal = closeModal ?? modalContext.closeModal;

  const [mounted, setMounted] = useState(false);
  props = props ?? {};

  return (
    // @ts-ignore
    <S.Modal
      ref={ref || null}
      width={width}
      isOpen
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={() => {
        if (allowClose && closeModal) {
          closeModal();
        }
      }}
      onAfterOpen={() => {
        onOpen();
        setMounted(true);
        document.body.style.overflow = 'hidden';
      }}
      onAfterClose={() => {
        setMounted(false);
        onClose();
        document.body.style.overflow = 'auto';
      }}
      style={{
        content: {
          padding: 'var(--large) var(--large) var(--2x-large)',
          transition: '0.25s ease-in-out',
          opacity: mounted ? 1 : 0,
          outline: 'none',
          top: '2rem',
          ...contentStyles
        },
        overlay: {
          zIndex: 1000,
          background: 'rgba(1, 27, 42, 0.57)',
          transition: '0.2s ease-in-out',
          opacity: mounted ? 1 : 0,
          overflowY: 'scroll',
        }
      }}
      {...props}
    >
      <S.ModalContainer>
        {children}
        {allowClose && <S.ModalCloseIcon onClick={closeModal} />}
      </S.ModalContainer>
    </S.Modal>
  );
}) as ForwardRefExoticComponent<Omit<ModalProps, 'ref'> & RefAttributes<unknown>> & {
  Title: typeof S.ModalTitle,
  Content: typeof S.ModalContent,
  Footer: typeof S.ModalFooter
};

// Add inner modal elements
Modal.Title = S.ModalTitle;
Modal.Content = S.ModalContent;
Modal.Footer = S.ModalFooter;

export default Modal;