import styled from 'styled-components';


export const FeedbackContainer = styled.div`
  &&& {
    & > :nth-child(1),
    & > :nth-child(2) {
      margin: 0 0 var(--x-small) 0
    }
  }
`;

export const FeedbackCheckboxContainer = styled.div`
  &&& .fields {
    margin: var(--small) 0 var(--large) 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: .75rem;
    column-gap: var(--large);

    .field {
      margin: 0 !important;
    }
  }
`;