import { useEffect } from 'react';
import { useProjectContext } from '~/hooks';
import { useDerivedIllinoisRECFields } from './useDerivedIllinoisRECFields';
import { RecsData } from '~/types/project';

export const REC_DATA_STATES = ['New Jersey', 'Illinois'];

export const useDerivedRECFields = () => {
  const { project, updateProjectValue } = useProjectContext();
  const addressState = project?.addressState as string;

  // Set REC data when addressState gets set
  useEffect(() => {
    if (REC_DATA_STATES.includes(addressState) && !project.recsData?.[addressState]) {
      const recsData: RecsData = project?.recsData || {};
      recsData[addressState] = recsData[addressState] || {};
      updateProjectValue('recsData', recsData);
    };
  }, [project.addressState]);


  // Update REC values for Illinois state
  useDerivedIllinoisRECFields();
};