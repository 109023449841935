import axios from '~/utils/axiosInstance';
import { storage } from '~/utils';
import { LANDING_TOASTS, LANDING_TOAST_QUERY_PARAM } from '~/hooks/useLandingToasts/constants';

interface LoginParams {
  email?: string;
  password?: string;
}

interface ResetPasswordParams {
  password: string;
  passwordResetToken: string;
}

export const login = async function ({email, password}: LoginParams = {}) {
  const response = await axios.post('/auth/login', {email, password});
  storage.storeCurrentUser(response.data);
  return response.data;
};

export const logout = async function () {
  const response = await axios.post('/auth/logout');
  return response.data;
};

export const verifyPasswordResetToken = async function (token: string) {
  await axios.get(`/auth/password-reset/${token}`);
};

export const sendPasswordResetEmail = async function (email: string) {
  const response = await axios.post('/auth/forgot', {email});
  return response.data;
};

export const resetPassword = async function ({password, passwordResetToken}: ResetPasswordParams) {
  const response = await axios.post('/auth/reset-password', {password, passwordResetToken});
  return response.data;
};

export const confirmEmail = async function (token: string) {
  const response = await axios.post('/auth/confirm-email', { token });
  storage.storeCurrentUser(response.data);
  window.location.href = `/?${LANDING_TOAST_QUERY_PARAM}=${LANDING_TOASTS.email_confirm}`;
  return response.data;
};

export const resendConfirmationEmail = async function () {
  const response = await axios.post('/auth/confirm-email/resend');
  return response.data;
};