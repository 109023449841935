import { useState } from 'react';
import * as S from './styles';
import { Icon, Label } from 'semantic-ui-react';
import { Button } from '~/components';
import Link from '~/components/Link';
import { usePortfolioContext } from '~/hooks';
import AcceptPortfolioBidModal from './modals/AcceptPortfolioBids';
import DeclinePortfolioBidModal from './modals/DeclinePortfolioBids';
import { useRequiredResponseCountsQuery } from '~/requests/projects/useRequiredResponseCounts';
import useArchiveToast from '~/hooks/useArchiveToast';

export default function Actions() {
  const [acceptInvestorId, setAcceptInvestorId] = useState('');
  const [declineInvestorId, setDeclineInvestorId] = useState('');

  const { portfolio, portfolioDisplayValues } = usePortfolioContext();
  const dealSummary = portfolioDisplayValues?.dealSummary;
  const { data: requiredResponseCountsData } = useRequiredResponseCountsQuery();

  const { showArchiveToast } = useArchiveToast();

  return (
    <>
      {acceptInvestorId && <AcceptPortfolioBidModal investorId={acceptInvestorId} closeModal={() => setAcceptInvestorId('')} />}
      {declineInvestorId && <DeclinePortfolioBidModal investorId={declineInvestorId} closeModal={() => setDeclineInvestorId('')} />}

      <S.ActionCell />
      {dealSummary?.investors?.map((investor: any, i: number) => {
        const requiredResponseCount = requiredResponseCountsData
          ?.filter((data: any) => data.investorId === investor.id)
          ?.reduce((acc: number, data: any) => acc + data.count, 0)
          ?? 0;
        return (
          <S.ActionCell key={`comments-${i}`}>
            <Link to={`/portfolio/${portfolio.id}/messages?investorId=${investor.id}`} style={{ textDecoration: 'none' }}>
              <Icon
                name="comment alternate outline" 
                style={{ color: requiredResponseCount ? 'var(--color-secondary)' : 'var(--color-charcoal)' }}
              />
            </Link>
            {requiredResponseCount ? (
              <Label circular color='red'>
                {requiredResponseCount} 
              </Label>
            ) : null}
          </S.ActionCell>
        );
      })}

      <S.ActionCell />
      
      {dealSummary.investors.map((investor: any, i: number) => {
        const hasPendingBid = Object.values(dealSummary?.investorProjectBidMap?.[investor?.id] ?? {}).some((bid: any) => bid?.pendingBid);

        return (
          // Button is disabled if there is no pending bid for the investor
          <S.ActionCell key={i}>
            {!dealSummary?.investorProjectMap?.[investor?.id]?.conversationEnded && (
              <Button
                size="small" 
                primary
                onClick={() => {
                  if (!showArchiveToast()) {
                    setAcceptInvestorId(investor.id);
                  }
                }}
                disabled={!hasPendingBid}
              >
                Accept
              </Button>
            )}
          </S.ActionCell>
        );
      })}
        
      <S.ActionCell />
      {dealSummary.investors.map((investor: any, i: number) => (
        <S.ActionCell key={i}>
          {!dealSummary?.investorProjectMap?.[investor?.id]?.conversationEnded && (
            <Button
              size="small"
              primary
              outlined
              onClick={() => {
                if (!showArchiveToast()) {
                  setDeclineInvestorId(investor.id);
                }
              }}
            >
              Decline
            </Button>
          )}
        </S.ActionCell>
      ))}
      
    </>
  );
}