import { DealStates, PricingType } from '~/constants';
import { formatDollars } from '~/utils/formatNumber';
import { display } from '~/utils/displayValues/common';
import { UnitDecimals } from '~/constants';
import { Project } from '~/types/project';

export default function epcCost(project: Project, projectCosts: any) {
  if (project?.pricingType === PricingType.ACQUISITION && projectCosts?.bid === undefined) {
    return {
      perWdc: 'TBD',
      total: 'TBD'
    };
  } else {

    // Show highlighted variant if projet status is IN_DILIGENCE and pricing type is ACQUISITION
    const isHighlighted = project?.dealState >= DealStates.IN_DILIGENCE && project?.pricingType === PricingType.ACQUISITION;

    return {
      perWdc: display(projectCosts?.perWdc?.epcCost && formatDollars(projectCosts.perWdc.epcCost, { dec: UnitDecimals.WDC, default0: 'TBD' })),
      total: display(projectCosts?.overall?.epcCost && formatDollars(projectCosts.overall.epcCost, { dec: UnitDecimals.TOTAL, default0: 'TBD' })),
      isHighlighted
    };
  }
}