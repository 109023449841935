import * as S from './styles';
import SidebarTabs from '~/components/SidebarTabs';
import { useDataroomContext } from '../../providers/DataroomProvider';
import { Label, Popup as _Popup } from 'semantic-ui-react';
import ProgressBar from '../ProgressBar';
import { DealStates } from '~/constants';
import styled from 'styled-components';
import { usePermissions } from '~/requests/permissions/usePermissions';
import Link from '~/components/Link';
import { useState } from 'react';
import { Button, Modal } from '~/components';
import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import * as AdminApi from '~/api/admin';
import { useDataroomQuery } from '~/requests/dataroom';
import PortfolioProjectSwitcher from '~/components/PortfolioProjectSwitcher';

const TabContainer = styled.div<{ disabled?: boolean }>`
  ${({ disabled }) => disabled && `
    position: relative;
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      z-index: 1;
      background: var(--color-off-white);
      opacity: 0.5;
      display: block;
      position: absolute;
    }
    &:hover {
      cursor: not-allowed;
    }
  `}
`;

const Popup = styled(_Popup)``;
Popup.defaultProps = {
  position: 'bottom center',
  inverted: true,
  on: ['hover', 'focus']
};

export default function DocumentTypeSidebar() {
  const {
    dataroom,
    doclist,
    selectedCategory,
    setSelectedCategory,
    dataroomCounts,
    dealState,
    isSimplifiedUI,
    setEditingDocumentTypes
  } = useDataroomContext();

  const { permissions } = usePermissions();
  const [uiToggleModalOpen, setUiToggleModalOpen] = useState(false);
  const isDiligence = dealState >= DealStates.IN_DILIGENCE;

  const { updateDataroomCache } = useDataroomQuery();

  const { mutate: toggleDocumentsUI, status: toggleDocumentsUIStatus } = useMutation(async () => {
    try {
      const newDataroom = await AdminApi.toggleDataroomUI(dataroom.id);
      setUiToggleModalOpen(false);
      updateDataroomCache(newDataroom);
      setEditingDocumentTypes(false);
      toast.success('Successfully changed project to ' + (!isSimplifiedUI ? 'simple' : 'power') + ' user interface', { duration: 5000 });
    } catch (err) {
      console.error(err);
      toast.error('An error occurred while updating the UI. Please refresh the page and try again.', { duration: 5000 });
    }
  });

  // Hide sidebar for simplified UI
  if (isSimplifiedUI && !permissions.isAdmin) {
    return null;
  }

  return (
    <div className="dataroom-sidebar">
      <SidebarTabs>
        {isSimplifiedUI && (
          <SidebarTabs.Tab active>
            <S.TabContent>
              <div>Recommended Documents</div>
            </S.TabContent>
          </SidebarTabs.Tab>
        )}
        {!isSimplifiedUI ? <PortfolioProjectSwitcher /> : null}
        <TabContainer disabled={isSimplifiedUI}>
        {doclist?.map((category: any) => {
          const { uploaded, approved, total } = dataroomCounts.categorizedCounts?.[category?.id as string] ?? {uploaded: 0, approved: 0, total: 0 };
          return (
            <SidebarTabs.Tab
              key={category?.id}
              active={!isSimplifiedUI && category?.id === selectedCategory?.id}
              onSelect={() => !isSimplifiedUI && setSelectedCategory(category)}
            >
              <S.TabContent data-testid={`category-${category?.id}-tab`}>
                <div>{category?.name}</div>
                {isDiligence && (
                  <>
                    {isDiligence && !!uploaded && !!total && uploaded === total && approved < total && (
                      <Popup
                        trigger={<Label circular tabIndex={0}>{uploaded}/{total}</Label>}
                        content="All recommended documents have been uploaded but not all have been approved"
                      />
                    )}
                    {!isDiligence && !!uploaded && !!total && uploaded === total && (
                      <Popup
                        trigger={<div className="complete-checkmark"><S.SuccessCheckmark tabIndex={0} /></div>}
                        content="All recommended documents have been uploaded"
                      />
                    )}
                    {!total && (
                      <Popup
                        trigger={<Label circular tabIndex={0}>N/A</Label>}
                        content="No documents are required for this category"
                      />
                    )}
                    {!!total && uploaded < total && (
                      <Popup
                        trigger={<Label circular tabIndex={0}>{uploaded}/{total}</Label>}
                        content={`${uploaded}/${total} documents uploaded`}
                      />
                    )}
                    {isDiligence && approved !== undefined && approved < total && (
                      <Popup
                        trigger={<Label circular color='green' tabIndex={0}>{approved}</Label>}
                        content={`${approved} document${approved !== 1 ? 's' : ''} approved`}
                      />
                    )}
                    {isDiligence && approved > 0 && total && approved === total && (
                      <Popup
                        trigger={<div className="complete-checkmark"><S.SuccessCheckmark tabIndex={0} /></div>}
                        content="All recommended documents have been uploaded and approved"
                      />
                    )}
                  </>
                )}
              </S.TabContent>
            </SidebarTabs.Tab>              
          );
        })}
        </TabContainer>
        {isDiligence && (
          <S.ProgressBarContainer>
            <ProgressBar/>
          </S.ProgressBarContainer>
        )}
        {!isDiligence && permissions.isAdmin && (
          <Link style={{ marginTop: 'var(--x-large)', marginBottom: 'var(--large)' }} as="button" underline onClick={() => setUiToggleModalOpen(true)}>
            {isSimplifiedUI ? 'Switch to power user interface' : 'Switch to simple user interface'}
          </Link>
        )}
        {uiToggleModalOpen && (
          <Modal closeModal={() => toggleDocumentsUIStatus !== 'loading' && setUiToggleModalOpen(false)}>
            <Modal.Title>
              {isSimplifiedUI ? 'Switch to power user interface' : 'Switch to simple user interface'}
            </Modal.Title>
            <Modal.Content>
              This will change the interface to the {isSimplifiedUI ? 'power user' : 'simple user'} interface. Are you sure you want to continue?
            </Modal.Content>
            <Modal.Footer>
              <Button 
                outlined 
                primary 
                onClick={() => setUiToggleModalOpen(false)} 
                disabled={toggleDocumentsUIStatus === 'loading'}
              >
                Cancel
              </Button>
              <Button 
                primary 
                disabled={toggleDocumentsUIStatus === 'loading'} 
                loading={toggleDocumentsUIStatus === 'loading'} 
                onClick={() => toggleDocumentsUI()}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </SidebarTabs>
    </div>
  );
}



