import { DealStates } from '~/constants';
import styled from 'styled-components';
import { colors } from '~/theme';
import { Label } from 'semantic-ui-react';
import { useDataroomContext } from '../../providers/DataroomProvider';
import { DataroomCounts } from '~/utils/calculators/dataroomCounts';

interface StatusProps {
  width: number; 
  backgroundColor: string; 
  zIndex: number; 
}

const Container = styled.div`
    width: 100%;
`;

const StatusBar = styled.div`
  position: relative;
  width: 100%;  
  height: 1.5rem;
  margin-bottom: var(--x-small);
  border: 1.5px ${colors.charcoal} solid; 
  border-radius: 3px;
`;

const Status = styled.div<StatusProps>`
  position: absolute;
  top: 0px;
  left: 0px;
  width: ${props => props.width}%;
  height: 100%;
  background-color: ${props=> props.backgroundColor};
  border-radius: 1px; 
  z-index: ${props => props.zIndex};
`;  

const LabelContainer = styled.div`
  padding: var(--2x-small) 0;

  & > * {
      font-size: 11px;
      font-weight: 400;
    }  
`;

export default function ProgressBar() {
  const { dataroomCounts, dealState } = useDataroomContext();
  const counts = dataroomCounts as DataroomCounts;
  const isDiligence = dealState >= DealStates.IN_DILIGENCE;
  
  const percentApproved = (counts.approved / counts.total * 100);
  const percentUploaded = (counts.uploaded / counts.total * 100);
  
  return (
    <Container>
      <StatusBar>
        {isDiligence ? (
          <>
            <Status 
              width={percentApproved} 
              backgroundColor={colors.green} 
              zIndex={1}/>
            <Status 
              width={percentUploaded} 
              backgroundColor={colors.gray} 
              zIndex={0}/>
          </>
          ) : (
            <Status 
            width={percentUploaded} 
            backgroundColor={colors.green} 
            zIndex={1}/>
        )}  
      </StatusBar>
      <LabelContainer>
        {isDiligence ? (
          <> 
            <p>
              <Label 
                circular 
                color='green' 
                style={{ marginRight: 'var(--x-small)'}}>
                  {`${counts.approved}/${counts.uploaded}`}
              </Label>
              Approved
            </p>
            <p>
              <Label 
                circular 
                style={{ marginRight: 'var(--x-small)'}}>
                  {`${counts.uploaded}/${counts.total}`} 
              </Label>
              Recommended documents uploaded
            </p>
          </>
        ) : (  
          <p>
            <Label 
              circular
              color='green' 
              style={{ marginRight: 'var(--x-small)'}}>
                {`${counts.uploaded}/${counts.total}`} 
            </Label>
            Recommended documents uploaded
          </p>
        )}
      </LabelContainer>
    </Container>  
  );
}; 