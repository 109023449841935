import styled from 'styled-components';
import { colors } from '~/theme';
import { debounce } from 'lodash';
import { usePageType, useProjectContext } from '~/hooks';
import { useAdminNotes } from '~/requests/admin/adminNotes';
import { Form, TextArea, Loader } from 'semantic-ui-react';
import { useUpdateAdminNotes } from '~/mutations/admin/adminNotes';
import { useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';

const Container = styled.div`
  &&& {
    position: relative;
    max-width: 37.5rem;

    textarea {
      color: var(--color-charcoal);
    }
  }
`;

const NotesField = styled(Form.Field)`
  &&& {
    max-width: 37.5rem;
    ${props => props.isLoading && `background: ${colors.gray};`}
  }
`;

const LoadingIndicator = styled(Loader)`
  position: absolute;
  inset: 0;
  z-index: 100;
`;

interface AdminNotesProps {
  preferencesOnly?: boolean;  
}

function AdminNotes({ preferencesOnly }: AdminNotesProps) {
  const debouncedChange = useRef() as any;
  const pageType = usePageType();
  const { project } = useProjectContext();
  const { mutate } = useUpdateAdminNotes() as any;
  const { data, status } = useAdminNotes(project?.id, {
    staleTime: Infinity,
    refetchOnMount: true,
    refetchOnWindowFocus: false
  }) as any;
  const [value, setValue] = useState();

  useEffect(() => {
    setValue(data?.notes ?? '');
  }, [data] );

  const runUpdate = (value: any) => {
    mutate({ projectId: project.id, notes: value });
  };

  const handleChange = (e: any) => {
    setValue(e.target.value);
    if (debouncedChange?.current?.cancel) {
      debouncedChange.current.cancel();
    }
    debouncedChange.current = debounce(() => runUpdate(e.target.value), 750);
    debouncedChange.current();
  };

  const isLoading = status === 'loading';

  if (pageType !== 'project') {
    return null;
  }

  return (
    <Form style={{ margin: preferencesOnly ? '0' : 'var(--large) 0' }}>
      <Container>
        <NotesField
          control={TextArea}
          label="Admin Notes"
          onChange={handleChange}
          value={value}
          isLoading={isLoading}
          disabled={isLoading}
        />
        {isLoading && <LoadingIndicator active size="large" />}
        {data?.updatedAt && <em>Last updated on {format(new Date(data.updatedAt), 'MM/dd/yyyy \'at\' hh:mm:ss a')}</em>}
      </Container>
    </Form>
  );
}

export default AdminNotes;