import styled from 'styled-components';

export const RecContainer = styled.div`
  &&& {
    .fields.inline {
      label {
        font-size: 0.875rem;
      }
   }
   .field {
    margin-bottom: var(--x-small);
   }
  }
`;

