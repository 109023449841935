import { format } from 'date-fns';
import Link from '~/components/Link';
import { TimestampStyles } from './styles';

export default function ReactivationTimestamp({ 
  id, 
  timestamp,
  type
}: { 
  id: string; 
  timestamp: string,
  type: string
}) {
  const pageType = type === 'project' ? 'project' : 'portfolio';
  const reactivationDate = format(new Date(timestamp), 'EEEE, MMMM d, yyyy, hh:mm a');

  return (
    <TimestampStyles>
      <Link
        to={`/${pageType}/${id}`}
        aria-label={`Previously archived ${pageType} is now reactivated. Click to view.`}
      >
        {type === 'portfolioProject' ? 'Portfolio reactivated on' : 'Reactivated on'}
      </Link>:{' '}
        {reactivationDate}.
    </TimestampStyles>
  );
}