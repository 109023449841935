import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { LANDING_TOAST_CONFIG, LANDING_TOAST_QUERY_PARAM } from './constants';

export default function useLandingToast() {
  // Get landing toast message code from query params
  const landingToastMessageCode = new URLSearchParams(window.location.search).get(LANDING_TOAST_QUERY_PARAM);

  useEffect(() => {
    if (landingToastMessageCode) {
      // Remove the query param from the URL but keep the rest of the query params. Replace url without refresh
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete(LANDING_TOAST_QUERY_PARAM);
      const newSearch = searchParams.toString();
      const newUrl = `${window.location.pathname}${newSearch ? '?' + newSearch : ''}`;
      window.history.replaceState({}, document.title, newUrl);
      const toastConfig = LANDING_TOAST_CONFIG[landingToastMessageCode];
      if (toastConfig) {
        toast[toastConfig.type](toastConfig.message, {
          duration: toastConfig.duration ?? 5000
        });
      }
    }
  }, []);
}