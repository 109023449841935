import * as S from '../../../../components/GridComponent';
import { useProjectContext } from '~/hooks/providers/ProjectContextProvider';
import React from 'react';

export default function BidTable() {
  const { projectDisplayValues } = useProjectContext();
  const detailsTable = projectDisplayValues?.investorBidArea?.detailsTable;

  return (
    <S.GridContainer columnValues='11rem auto'>
      <S.GridTable columnValues='11rem auto' gridColumn='1 / 4'>
        {detailsTable?.map(([label, value]: [string, any], i: number) => {
          return (
            <React.Fragment key={label}>
              <S.GridLabelEl>
                {label}
              </S.GridLabelEl>
              <S.GridTableEl gridColumn='2 / 4'>
                {value}
              </S.GridTableEl>
            </React.Fragment>
          );
        })}
      </S.GridTable>
    </S.GridContainer>
  );
}; 
