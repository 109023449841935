import { usePageType, usePortfolioContext, useProjectContext } from '~/hooks';
import { DealStates } from '~/constants';
import { Dropdown } from 'semantic-ui-react';
import { usePermissions } from '~/requests/permissions/usePermissions';
import { useState } from 'react';
import { useToggleArchiveMutation } from '~/mutations/archive';
import ArchiveConfirmationModal from './ArchiveConfirmationModal';

export default function ArchiveAction() {
  const pageType = usePageType();
  const { permissions } = usePermissions();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  // Determine deal state / action text (are we archiving? unarchiving? reactivating?)
  const { portfolio, portfolioState } = usePortfolioContext();
  const { project, projectState } = useProjectContext();
  const isArchived = portfolio?.isArchived || project?.isArchived;
  const dealState = portfolioState?.latestState ?? projectState?.dealState;

  let action: 'archive' | 'unarchive' | 'reactivate' = 'archive';
  if (isArchived && dealState > DealStates.READY_TO_SUBMIT) {
    action = 'reactivate';
  } else if (isArchived) {
    action = 'unarchive';
  }

  // Set up the mutation
  const { mutate: toggleArchive, status: toggleArchiveStatus } = useToggleArchiveMutation(action);

  // Do not render the archive action if the user does not have the necessary permissions
  if (!permissions?.hasArchiveActions) {
    return null;
  }

  return (
    <>
      <Dropdown.Item 
        text={`${action[0].toUpperCase() + action.slice(1)} ${pageType}`}
        onClick={() => {
          if (!isArchived && dealState > DealStates.READY_TO_SUBMIT) {
            setShowConfirmationModal(true);
          } else {
            toggleArchive();
          }}}
        loading={toggleArchiveStatus === 'loading'}
        disabled={toggleArchiveStatus === 'loading'}
      >
      </Dropdown.Item>
      {showConfirmationModal && 
        <ArchiveConfirmationModal closeModal={() => setShowConfirmationModal(false)} />
      }
    </>
  );

};