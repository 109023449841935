import * as S from './styles';
import { useMemo, useState } from 'react';
import { usePortfolioContext } from '~/hooks';
import { useProjectList } from '~/requests/projects/useProjectList';
import { getAvailableProjectOptions } from '../../AddProjectsDropdown';
import { Dropdown } from 'semantic-ui-react';
import ProjectCheckbox from './ProjectCheckbox';
import Link from '~/components/Link';
import { useModalContext } from '~/hooks/providers/ModalProvider';
import CreateProjectModal from '~/modals/CreateProjectModal';
import { Project } from '~/types/project';

export default function AddProjectsDropdown() {
  const { portfolio } = usePortfolioContext();
  const [isOpen, setIsOpen] = useState(false);
  const { openModal } = useModalContext();

  // Get the list of projects that can be added to the portfolio
  const { data: projects } = useProjectList();
  const projectOptions = useMemo(() => {
    return getAvailableProjectOptions(projects, portfolio?.investorId, false)
      .filter((project: Project) => !portfolio?.projects?.find((p: Project) => p.id === project.id));
  }, [projects, isOpen]) as { name: string; id: string; [key: string]: any }[];

  if (portfolio?.isArchived) {
    return null;
  }

  return (
    <S.DropdownContainer>
      <Dropdown 
        trigger={<S.DropdownTrigger>Add project</S.DropdownTrigger>}
        pointing="top right"
        closeOnBlur={true}
        closeOnChange={false}
        scrolling
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
      >
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => openModal(CreateProjectModal, { portfolioId: portfolio.id })}>
            <Link as="button" 
              primary 
              underline 
              bold 
            >
              Create new project
            </Link>
          </Dropdown.Item>
          {projectOptions.map((project) => (
            <ProjectCheckbox key={project.id} project={project} />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </S.DropdownContainer>
  );
}