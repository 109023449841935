import Button from '~/components/Button';
import Modal from '~/components/Modal';
import { usePageType, useProjectContext } from '~/hooks';
import { useToggleArchiveMutation } from '~/mutations/archive';

export default function ArchiveConfirmationModal({ closeModal }: { closeModal: Function }) {
  const { project } = useProjectContext();

  let pageType = usePageType();

  const { mutate: toggleArchive } = useToggleArchiveMutation('archive');

  return (
    <Modal closeModal={closeModal}>
      <Modal.Title>
        Confirm {pageType} archiving
      </Modal.Title>
      <Modal.Content>
        {pageType === 'portfolio' && (
          <p>
            This portfolio has been submitted and may still be active. By archiving the portfolio, it and all its constituent projects will be hidden by default from your and your partners’ dashboards and they will no longer be editable. 
          </p>
        )}
        {pageType === 'project' && !project?.portfolioId && (
          <p>
            This project has been submitted and may still be active. By archiving the project, it will be hidden by default from your and your partners’ dashboards and it will no longer be editable.
          </p> 
        )}
        {pageType === 'project' && !!project?.portfolioId && (
          <>
            <p>
              This project is part of a portfolio. By archiving the project, it will be removed from the portfolio and will be hidden by default from your and your partners’ dashboards. The project will no longer be editable.
            </p>
            {project?.portfolio?.projects?.length === 2 && (
              <p>
                <strong>Warning:</strong> This is the second to last project in the portfolio. Archiving this project will convert the portfolio to a project.
              </p> 
            )}
          </>
        )}
      </Modal.Content>
      <Modal.Footer>
        <Button 
          primary 
          outlined 
          onClick={() => closeModal()}
        >
          Cancel
        </Button>
        <Button
          primary
          onClick={() => {
            toggleArchive();
            closeModal();
          }}
        >
          Archive {pageType}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}