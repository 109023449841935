import { camelCase, startCase } from 'lodash';
import { Label } from 'semantic-ui-react';
import { usePermissions } from '~/requests/permissions/usePermissions';
import { format } from 'date-fns';

interface ProjectStatusBadgeProps {
  statusDisplay: string;
  isArchived?: boolean;
  bidDeadline?: string;
}

export default function ProjectStatusBadge({ statusDisplay, bidDeadline, isArchived }: ProjectStatusBadgeProps) {
  const { permissions } = usePermissions();

  if (isArchived) {
    return <Label color="black">Archived</Label>;
  } else if (statusDisplay === 'NOT_SUBMITTED') {
    return <Label color="grey">Not Submitted</Label>;
  } else if (statusDisplay === 'BIDDING' && !permissions.isAdmin && bidDeadline) {
    // Render the bid deadline in M/DD format using date-fns
    return <Label color="olive">Bidding - Bids due {format(new Date(bidDeadline), 'M/dd')}</Label>;
  } else if (statusDisplay === 'BIDDING') {
    return <Label color="olive">Bidding</Label>;
  } else if (statusDisplay === 'BID_REVIEW') {
    return <Label color="olive">Bid Review</Label>;  
  } else if (statusDisplay === 'IN_DILIGENCE') {
    return <Label style={{ background: 'var(--color-light-blue)', color: 'var(--color-pure-black)' }}>In Diligence</Label>;
  } else if (statusDisplay === 'COMPLETED') {
    return <Label style={{ background: 'var(--color-light-blue)', color: 'var(--color-pure-black)' }}>Completed</Label>;
  } else if (statusDisplay === 'IN_REVIEW') {
    return <Label color="yellow">In Review</Label>;
  } else {
    return <Label color="black">{startCase(camelCase(statusDisplay))}</Label>;
  }
}