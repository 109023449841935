import { useEffect } from 'react';
import { Button } from '~/components';
import { Form, TextArea, Checkbox } from 'semantic-ui-react';
import { FormSchemaAwareWidget } from '~/components/form';
import { usePromiseWatcher, useForm, useProjectContext } from '~/hooks';
import styled from 'styled-components';
import { FormContextProvider } from '~/hooks/providers/FormContextProvider';
import { submitProject } from '~/api/projects';
import { getPriorInvestors } from '~/api/team';
import Modal from '~/components/Modal';
import { usePermissions } from '~/requests/permissions/usePermissions';

interface ProjectModalProps {
  closeModal: () => void;
}

const FormText = styled.p`
  &&& {
    color: var(--color-charcoal);
    font-size: .875rem;
    line-height: 1.25rem;
    padding: 0;
    margin: 0 0 var(--medium) 0;
  }
`;

function ProjectSubmissionFormImpl({closeModal}: ProjectModalProps) {
  const { project, id, updateProjectValue, projectState } = useProjectContext();
  const { permissions } = usePermissions();
  const { formState } = useForm() as any;

  const { execute, status } = usePromiseWatcher(
    async () => {
      const preferences = projectState.isRejected ? null : formState; 
      const project = await submitProject(id, {...preferences, rejectionDetail: null});
      const { 
        status, 
        dealState,
        preferredInvestors, 
        preferredInvestorDetails, 
        installerAcknowledgmentFlag, 
        installerAcknowledgmentExceptions, 
        autoGrantDocAccess,
        apiEvents
      } = project;
      updateProjectValue('', { 
        status, 
        dealState,
        preferredInvestors, 
        preferredInvestorDetails, 
        installerAcknowledgmentFlag, 
        installerAcknowledgmentExceptions,
        autoGrantDocAccess,
        apiEvents
       }, { doSave: false, patch: true });
    },
    {messageStub: 'submitting your project'},
    [id, formState]
  );

  const {execute: executePriorInvestors, value: priorInvestors} = usePromiseWatcher(
    async () => {
      if (!project?.installer?.id) {
        return [];
      }
      const investors = await getPriorInvestors(project?.installer?.id);
      return investors.map((investor: any) => ({key: investor.id, value: investor, text: investor.name}));
    },
    'fetching the Investors you\'ve matched with previously',
    [project?.installer?.id]
  );

  useEffect(executePriorInvestors, [project?.installer?.id]);

  return(
    <Modal allowClose closeModal={status !== 'pending' ? closeModal : undefined}>
      <Modal.Title>
        Confirm project submission
      </Modal.Title>
      <Modal.Content>
        <Form>
          <FormText>Conductor&apos;s algorithm will match you with the best investor options for your project. If you&apos;d like to ensure certain investors do (or don&apos;t) see your project, indicate that below.</FormText>
          <FormSchemaAwareWidget
            schemaKey="preferredInvestors"
            placeholder="Previously matched investors"
            multiple
            options={priorInvestors}
            maxSelections={5}
            style={{fontSize: '0.875rem'}}
          />  
          <FormSchemaAwareWidget as={TextArea}
            schemaKey="preferredInvestorDetails"
            placeholder="Use this space to provide any additional context, including other preferred investors who are not on the above list or investors with whom you do not want to be matched."
            style={{fontSize: '0.875rem'}}
            rows='6'
          />
          <FormSchemaAwareWidget as={Checkbox}
            schemaKey="autoGrantDocAccess"
            label='Share my uploaded docs with investors'
            fieldLabelStyle={{fontSize: '0.875rem'}}
          />
          <Modal.Footer>
            <Button
              primary
              outlined
              size="medium"
              onClick={() => closeModal()}
            >
              Cancel
            </Button>
            <Button 
              primary  
              size="medium"
              onClick={execute}
              loading={status === 'pending'}
              disabled={!permissions.hasProjectDeveloperAccess || formState.preferredInvestors.length > 5 || status === 'pending'}
            >
              Submit project 
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Content>
    </Modal>
  );
} 

export const ProjectSubmissionModal = ({closeModal}: ProjectModalProps) => {
  return (
    <FormContextProvider defaults={{
      autoGrantDocAccess: true,
      preferredInvestors: [],
      preferredInvestorDetails: '',
    }}>
      <ProjectSubmissionFormImpl closeModal={closeModal}/>
    </FormContextProvider>
  );
};
