import { autopricing, pollAsyncRequestResult } from '~/api/projects';
import { Button } from '~/components';
import { useAutoSaveContext } from '~/hooks';
import { DealStates } from '~/constants';
import { Popup } from 'semantic-ui-react';
import { Project } from '~/types/project';
import toast from 'react-hot-toast';
import AsyncRequestButton from '~/components/AsyncRequestButton';
import useArchiveToast from '~/hooks/useArchiveToast';

export default function PricingEstimateButton({ project, projectState }: { project: Project, projectState: any }) {
  const hasPricingEstimate = Boolean(project.priceEstimateTimestamp);  
  const { fetchFreshData } = useAutoSaveContext() as any;
  const dealState = projectState?.dealState ?? 0;
  const { showArchiveToast } = useArchiveToast();

  if (dealState !== DealStates.PROJECT_HAS_ERRORS) {
    return (
      <div>
        <AsyncRequestButton
          apiEvent={project.apiEvents?.autopricing}
          onClick={async () => {
            if (showArchiveToast()) {
              return;
            }

            try {
              return await autopricing(project.id);
            } catch (err) {
              toast.error('Error initializing pricing estimate request', { duration: 5000 });
              throw err;
            }
          }}
          onSuccess={() => {
            fetchFreshData().catch((err: any) => {
              console.error('Error fetching fresh data after making pricing estimate request', err);
              toast.error('An unexpected error occurred while fetching project data after finishing the pricing estimate request. Please refresh the page.', { duration: 10000 });
            });
          }}
          onError={(errorObj: any) => {
            if (errorObj?.response?.data?.outOfBounds) {
              toast.error((
                <div>
                  <p>
                    Conductor Solar was unable to find a workable price range for your project. Please review your project details,
                    paying specific attention to the project size and overall costs.
                  </p>
                  <p>
                    If you believe this is in error, please contact{' '}
                    <a href="mailto:support@conductor.solar">support@conductor.solar</a> for assistance.
                  </p>
                </div>
              ), {
                duration: 20000
              });
            } else if (errorObj?.response?.data?.errorInIRR) {
              toast.error((
                <div>
                  <p>
                    Conductor Solar is having difficulty generating a price, and this is likely due to choosing the wrong unit selector for one of your inputs (e.g., year 1 production).
                  </p>
                  <p>
                    After you&apos;ve confirmed your inputs, please contact{' '}
                    <a href="mailto:support@conductor.solar">support@conductor.solar</a> for assistance.
                  </p>
                </div>
              ), { 
                duration: 20000 
              });
            } else {
              toast.error('An error occurred fetching pricing estimate data. Please refresh the page and try again.');
              fetchFreshData();
            }
          }}
          onTimeout={() => toast.error('Pricing estimates are taking longer than expected. Please refresh the page to check for results.')}
          text={hasPricingEstimate ? 'Update pricing estimate' : 'Generate pricing estimate'}
          loadingText={hasPricingEstimate ? 'Updating pricing estimate' : 'Generating pricing estimate'}
          pollFn={(apiEventId: string) => pollAsyncRequestResult(project.id, apiEventId)}
        />
      </div>
    );
  } else {
      return (
        <div>
          <Popup
            inverted
            position="left center"
            content="Additional data is required to run autopricing on this project. Please fill in any remaining required fields in the data section to enable autopricing."
            on={['hover', 'focus']}
            trigger={
              <span style={{ display: 'inline-block' }} tabIndex={0}>
                <Button outlined disabled>
                  {hasPricingEstimate ? 'Update pricing estimate' : 'Generate pricing estimate'}
                </Button>
              </span>
            }
          />
        </div>
      );
    }
}

    