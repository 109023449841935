import Link from '~/components/Link';
import { useForm, usePromiseWatcher } from '~/hooks';
import { login } from '~/api/auth';
import { Button } from '~/components';
import { FormSchemaAwareWidget } from '~/components/form';
import { FormContextProvider } from '~/hooks/providers/FormContextProvider';
import * as S from '../styles';
import AuthForm  from '../components/AuthForm';


const LoginFormImpl = () => {
  const {formState} = useForm() as any;
  const {execute, status} = usePromiseWatcher(
    async () => {
      // Attempt login
      const loginValue = await login(formState);
      if (loginValue?.reqwuiredEmailVerification) {
        // Redirect to email verification
        window.location.href = '/confirm-email';
        return null;
      }
      if (!loginValue) {
        throw new Error('Login is unsuccessful');
      }
      // Redirect if login success
      const urlParams = new URLSearchParams(window.location.search);
      const redirectUri = urlParams.get('redirect_uri');
      if (redirectUri) {
        window.location.href = redirectUri;
      } else {
        window.location.href = '/';
      }
    },
    {
      loading: null,
      success: null,
      error: 'Login unsuccessful. Please try again.'
    },
    [formState],
    {toastOpts: { id: 'login', duration: 5000 }} as any
  ); 

  return (
    <AuthForm header = 'Log In'>
      <FormSchemaAwareWidget
        schemaKey="email"
        fluid
        fieldLabel="E-mail Address"
        icon="envelope"
        iconPosition="left"
        placeholder="E-mail address"
        autoComplete="username"
      />
      <FormSchemaAwareWidget
        schemaKey="password"
        fluid
        fieldLabel="Password"
        icon="lock"
        iconPosition="left"
        placeholder="Password"
        type="password"
        autoComplete="current-password"
      />
      <Button 
        fluid 
        secondary
        size="large" 
        disabled={formState.email.length === 0 || formState.password.length === 0 || status ==='pending' || status === 'success'}
        loading={status === 'pending' || status === 'success'} 
        onClick={execute}
      >
        Log In
      </Button>
      <S.Message>
        <Link to="/forgot-password">Forgot your password?</Link>
      </S.Message>
    </AuthForm>
    );
};

const LoginForm = ({defaults = {
  email: '',
  password: '',
}}) => {
  return (
    <FormContextProvider defaults={defaults}>
      <LoginFormImpl/>
    </FormContextProvider>
  );
};

export default LoginForm;
