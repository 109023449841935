export const LANDING_TOAST_QUERY_PARAM = 'landing_toast';

export const LANDING_TOASTS = {
  email_confirm: 'email_confirm',
};

export const LANDING_TOAST_CONFIG: {
  [key: string]: {
    type: 'success' | 'error';
    message: string;
    duration?: number;
  };
} = {
  [LANDING_TOASTS.email_confirm]: {
    type: 'success',
    message: 'Email confirmed successfully!',
    duration: 4000
  }
};