import { usePageType, usePortfolioContext, useProjectContext, useUserContext } from '~/hooks';
import { Header, Label } from 'semantic-ui-react';
import styled from 'styled-components';
import { format } from 'date-fns';
import Link from '~/components/Link';
import { DealStates } from '~/constants';
import { useConversationContext } from '../providers/ConversationProvider';

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  &&& h2 {
    margin-bottom: var(--medium);
  }
`;

export default function MessagingHeader() {
  const { loggedInUser } = useUserContext() as any;
  const { project, projectState } = useProjectContext();
  const dealState = projectState?.dealState ?? 0;
  const { portfolio, portfolioState } = usePortfolioContext();
  const { selectedConversation, isSupportConversation, selectedProject } = useConversationContext();
  const pageType = usePageType();
  const isActive = selectedConversation?.isActive;
  const isInactive = selectedConversation && !selectedConversation.isActive;
  const bidDeadline = portfolio?.bidDeadline ?? project?.bidDeadline ?? project?.portfolio?.bidDeadline;
  const showBidDeadline = 
    !!bidDeadline && 
    isActive &&
    (pageType === 'project'
      ? dealState >= DealStates.AWAITING_BID && dealState < DealStates.IN_DILIGENCE
      : portfolioState?.earliestState >= DealStates.AWAITING_BID && portfolioState?.earliestState < DealStates.IN_DILIGENCE);

  let headerName;  
  if (!selectedConversation) {
    return null;
  } else if (pageType === 'portfolio' && !isSupportConversation) {
    headerName = (
      <Header as="h2">
        <Link to={`/project/${selectedConversation?.projectId}/review`} primary underline>
          {selectedProject?.name ?? selectedConversation?.name}{isInactive ? <em> — Inactive Conversation</em> : null}
        </Link>
      </Header>
    );
  } else if (loggedInUser.isAdmin && isSupportConversation) {
    headerName = <Header as="h2">Support conversation: {selectedConversation.name}</Header>;
  } else if (isSupportConversation) {
    headerName = <Header as="h2">Conversation with {selectedConversation.name}</Header>;
  } else {
    headerName = <Header as='h2'>{selectedConversation?.name}{isInactive ? <em> — Inactive Conversation</em> : null}</Header>;
  }

  return (
    <HeaderContainer style={headerName === null ? { marginTop: '-1rem' } : {}}>
      {headerName}
      {showBidDeadline && (
        <Label 
          size='medium' 
          style={{
            fontSize: '0.875rem',
            padding: 'var(--x-small) var(--small)', 
            marginLeft: 'auto',
            marginBottom: 'var(--medium)',
          }}
        >
          Bids due {format(new Date(bidDeadline), 'MMM dd')}
        </Label>
      )}
    </HeaderContainer>
  );
};