import { PricingType, ProjectStatus, DealStates, ProjectStatusDisplay, MaxDealState, MinDealState } from '~/constants';
import { Project } from '~/types/project';
import { Portfolio } from '~/types/portfolio';


function getDealState(project: Project, projectErrors: any) {
  // Override the deal state generated from the API based on the project status and errors
  // This is needed since new worksheet errors can be introduced in the project after the project has been loaded
  let dealState = project.dealState;
  if (project?.status === ProjectStatus.DRAFT && Object.keys(projectErrors).length) {
    dealState = DealStates.PROJECT_HAS_ERRORS;
  } else if (dealState === DealStates.PROJECT_HAS_ERRORS && !Object.keys(projectErrors).length) {
    dealState = project?.rejectionDetail?.reason ? DealStates.RETURNED_TO_DRAFT : DealStates.READY_TO_SUBMIT;
  }
  return dealState;
}

export function getProjectStatus(project: Project, { asUppercase, addPrependOnClosed }: any = {}) {
  if (asUppercase === undefined) asUppercase = true;
  if (addPrependOnClosed === undefined) addPrependOnClosed = false;

  const projectStatus = project?.statusDisplay || project?.status;

  if (projectStatus === ProjectStatus.MATCHING) {
    if (project.parentId && !project.childSelected) {
      return 'BIDDING';
    } else if (!project?.bidSummary?.length) {
      return 'IN REVIEW';
    } else {
      return 'BIDDING';
    }
  }

  let status = (ProjectStatusDisplay[projectStatus] ?? projectStatus?.replace('_', ' '));
  if (addPrependOnClosed && [ProjectStatus.BID_REJECTED, ProjectStatus.DECLINED_TO_BID].includes(projectStatus)) {
    status = 'CLOSED - ' + status;
  }
  if (asUppercase) {
    status = status?.toUpperCase();
  }
  return status;
}

export function getProjectState(project?: any, projectErrors?: any, portfolio?: any) { //TODO: Add types?
  const projectState = {
    isRejected: project?.rejectionDetail?.reason,
    isDeclined: (portfolio && portfolio.investorId !== project.investorId) || project.status === ProjectStatus.DECLINED_TO_BID,
    hasAcceptedBid: !!project?.acceptedBid,
    hasPendingBid: (!project?.acceptedBid && !!project?.pendingBid) || !!project?.bidSummary?.find((row: any) => row?.bid?.bidStatus === 'PENDING'),
    hasDataroom: project?.dataroomCreated,
    isAcquisition: project?.pricingType === PricingType.ACQUISITION,
    isOfftake: project?.pricingType === PricingType.OFFTAKE,
    isITC: project?.pricingType === PricingType.ITC,
    expectedItcPercentage: project?.itcAdders?.reduce((acc: any, curr: any) => {
      acc += (curr.value || 0);
      return acc;
    }, 30) ?? 30,
    dealState: undefined
  } as any;
  projectState.dealState = getDealState(project, projectErrors);
  projectState.hasDataroom = projectState.dealState >= DealStates.IN_DILIGENCE;
  
  return projectState;
}

export function hasBiddableDealState(projectBlobs: any) {
  return !!projectBlobs.filter((blob: any) => !!blob?.project?.parentId).find((blob: any) => [DealStates.AWAITING_BID, DealStates.PENDING_BID, DealStates.DECLINED].includes(blob?.projectState?.dealState));
}

export function getProjectDeclines(projectBlobs: any, investorId: any) {
  return projectBlobs.filter((blob: any) => blob?.project?.investorId === investorId && blob?.projectState?.dealState === DealStates.DECLINED)?.length || 0;
}

export function getPortfolioProjectDealState(portfolio: Portfolio, parentProjectBlob: any) {
  const { project: parentProject, projectErrors } = parentProjectBlob;
  const childProjects = portfolio?.projects?.filter((project: Project) => project.parentId === parentProject.id);
  if(!childProjects?.length) {
    return [getDealState(parentProject, projectErrors)];
  }

  return childProjects.map((proj: any) => getProjectState(proj, [], portfolio).dealState);
};

export function getEarliestProjectState(projectBlobs: any, investorOnly=false) {
  let earliestState = MaxDealState;
  let latestState = MinDealState;
  const stateCounts = {} as any;
  Object.values(DealStates).forEach((dealState) => stateCounts[dealState] = 0);

  for (let projectBlob of projectBlobs) {
    if (investorOnly && !projectBlob.project.investorId) {
      continue;
    }
    earliestState = Math.min(earliestState, projectBlob.projectState.dealState);
    latestState = Math.max(latestState, projectBlob.projectState.dealState);
    stateCounts[projectBlob.projectState.dealState]++;
  }

  return {earliestState, latestState, stateCounts, isDeclined: earliestState === DealStates.DECLINED && latestState === DealStates.DECLINED};
}
