import { orderBy } from 'lodash';
import { ProjectFilters, ProjectFilterType } from '~/constants';

interface TableState {
  column: string;
  data: any[];
  direction: 'asc' | 'desc';
  filter?: ProjectFilterType;
}

interface TableAction {
  type: string;
  data?: any[];
  column?: string;
  filter?: ProjectFilterType;
}

export function filterProjects(projects: any[], filter: string) {
  switch (filter) {
    case ProjectFilters.ALL:
      return projects.filter(({ statusDisplay, isArchived, portfolioIsArchived }) => !isArchived && !portfolioIsArchived && statusDisplay !== 'BID_NOT_SELECTED' && statusDisplay !== 'DECLINED_TO_BID');
    case ProjectFilters.DRAFT:
      return projects.filter(({ statusDisplay, isArchived, portfolioIsArchived }) => statusDisplay === 'NOT_SUBMITTED' && !isArchived && !portfolioIsArchived);
    case ProjectFilters.IN_REVIEW:
      return projects.filter(({ statusDisplay, isArchived, portfolioIsArchived }) => statusDisplay === 'IN_REVIEW' && !isArchived && !portfolioIsArchived);
    case ProjectFilters.BIDDING:
      return projects.filter(({ statusDisplay, isArchived, portfolioIsArchived }) => statusDisplay === 'BIDDING' && !isArchived && !portfolioIsArchived);
    case ProjectFilters.BID_REVIEW:
      return projects.filter(({ statusDisplay, isArchived, portfolioIsArchived }) => statusDisplay === 'BID_REVIEW' && !isArchived && !portfolioIsArchived);
    case ProjectFilters.IN_DILIGENCE:
      return projects.filter(({ statusDisplay, isArchived, portfolioIsArchived }) => statusDisplay === 'IN_DILIGENCE' && !isArchived && !portfolioIsArchived);
    case ProjectFilters.COMPLETED:
      return projects.filter(({ statusDisplay, isArchived, portfolioIsArchived }) => statusDisplay === 'COMPLETED' && !isArchived && !portfolioIsArchived);
    case ProjectFilters.CLOSED:
      return projects.filter(({ statusDisplay, isArchived, portfolioIsArchived }) => statusDisplay === 'BID_NOT_SELECTED' || statusDisplay === 'DECLINED_TO_BID' || isArchived || portfolioIsArchived);
    case ProjectFilters.OPEN_ITEMS:
      return projects.filter(({ hasOpenConversation, requiresCustomerProposal, isArchived, portfolioIsArchived }) => (hasOpenConversation || requiresCustomerProposal) && !isArchived && !portfolioIsArchived);
    default:
      return projects;
  }
}

export const DefaultSortOrders: { [key: string]: 'asc' | 'desc' } = {
  name: 'asc',
  portfolioName: 'asc',
  investorName: 'asc',
  installerName: 'asc',
  statusDisplay: 'asc',
  location: 'asc',
  size: 'asc',
  bidDeadline: 'desc',
  updatedAt: 'asc'
};

export function projectListReducer(state: TableState, action: TableAction): TableState {
  const data = (action.data ?? state.data);

  const column = action.column ?? state.column;

  const sortData = (data: any) => {
    if (typeof data?.[column] !== 'string') {
      return data[column];
    } else if (column === 'location') {
      // Flip state and city so we can prioritize sort by state first
      const [state, city] = data[column]?.split(', ')?.reverse() ?? [];
      if (!state && !city) {
        return '';
      } else {
        return `${state}, ${city ?? ''}`.toLowerCase();
      }
    } else {
      return data?.[column]?.toLowerCase();
    }
  };

  const getSortedData = (data: any[], direction: 'asc' | 'desc') => {
    if (column === 'bidDeadline') {
      // No matter the direction, we want to prioritize projects with bid deadlines at the top.
      // Using a custom .sort instead of lodash orderBy to have more control over sorting
      return data.sort((a, b) => {
        if (!a.bidDeadline && !b.bidDeadline) {
          return 0;
        } else if (!a.bidDeadline) {
          return 1;
        } else if (!b.bidDeadline) {
          return -1;
        } else {
          const aTime = new Date(a.bidDeadline).getTime();
          const bTime = new Date(b.bidDeadline).getTime();
          return direction === 'asc' ? aTime - bTime : bTime - aTime;
        }
      });
    } else if (column === 'statusDisplay') {
     // Sort by statusDisplay first, and if the status is BIDDING sort further by bid deadline
     // For bidding statuses, we want to prioritize projects with bid deadlines at the top.
     return data.sort((a, b) => {
      if (a.statusDisplay === 'BIDDING' && b.statusDisplay === 'BIDDING') {
        if (!a.bidDeadline && !b.bidDeadline) {
          return 0;
        } else if (!a.bidDeadline) {
          return 1;
        } else if (!b.bidDeadline) {
          return -1;
        } else {
          const aTime = new Date(a.bidDeadline).getTime();
          const bTime = new Date(b.bidDeadline).getTime();
          return direction === 'asc' ? aTime - bTime : bTime - aTime;
        }
      } 
      return direction === 'asc' ? a.statusDisplay.localeCompare(b.statusDisplay) : b.statusDisplay.localeCompare(a.statusDisplay);
     }); 
    }

    return orderBy(data, [sortData], [direction]);
  };

  switch (action.type) {
    case 'INIT':
      return {
        column: state.column,
        data: getSortedData(filterProjects(data ?? [], state.filter ?? ProjectFilters.ALL), state.direction),
        direction: state.direction,
      };
    case 'CHANGE_SORT': {
      // If the user clicks the same column, reverse the sort direction
      // Otherwise use the default sort order for the column
      const direction = action.column === state.column 
        ? (state.direction === 'asc' ? 'desc' : 'asc')
        : (DefaultSortOrders[action.column as string] ?? 'asc');
      
      return {
        ...state,
        column,
        data: getSortedData(data, direction),
        direction,
      };
    }
    case 'CHANGE_FILTER':
      if (state.filter === action.filter) {
        return state;
      }
      return {
        ...state,
        filter: action.filter,
        data: getSortedData(filterProjects(data, action.filter as string), state.direction ?? 'desc')
      };
    default:
      throw new Error();
  }
}