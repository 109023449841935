import WizardForm from '~/features/projectWizard/components/WizardForm';
import { WizardField } from '~/features/projectWizard/components/Field';
import { RecOwnershipRadioOptions } from '~/constants';
import { useProjectContext } from '~/hooks';
import { useDerivedRECFields } from '~/features/projectWizard/derived';
import StateSpecificRECForm from './StateSpecificRECForm';
import * as S from './styles';
import RecValueAssumptions from './RecValueAssumptions';

export default function RenewableEnergyCredits () {
  const { project } = useProjectContext();

  // Update REC data based on field changes
  useDerivedRECFields();

  return (
    <S.RecContainer>
      <WizardForm>
        <WizardField
          type="radio"
          fieldLabel="Renewable energy certificates ownership"
          schemaKey="recOwnership"
          values={RecOwnershipRadioOptions}
        />
        <RecValueAssumptions project={project} />
        <StateSpecificRECForm />
        <WizardField
          type="textarea"
          fieldLabel='REC Details'
          schemaKey="recDetails"
          placeholder="If needed, please describe any REC nuances that may be pertinent to pricing."
          optional
          fieldStyle={{ marginTop: project.addressState === 'Illinois' ? 'var(--x-large)' : undefined }}
        />
      </WizardForm>
    </S.RecContainer>
  );
}

