import { DealStates, ProjectStatus, ProjectStatusDisplay } from '~/constants';
import { parseISO, isAfter, isSameDay } from 'date-fns';
import { Portfolio } from '~/types/portfolio';

export default function status(portfolio: Portfolio, portfolioState: any, permissions: any) {
  const currentDate = new Date();
  const portfolioBidDeadline = parseISO(portfolio?.bidDeadline? portfolio.bidDeadline : '');
  const isBidDeadlinePassed = isAfter(currentDate, portfolioBidDeadline) || isSameDay(currentDate, portfolioBidDeadline);

  if (portfolio?.reactivatedPortfolio) {
    return 'Reactivated';
  }
  
  if (portfolio?.isArchived) {
    return 'Archived';
  }

  if (permissions.hasPortfolioInvestorAccess && !permissions.isAdmin) {
    switch (portfolioState.earliestState) {
      case DealStates.IN_DILIGENCE:
        return ProjectStatusDisplay.IN_DILIGENCE;
      case DealStates.DECLINED:
        return 'Projects declined';
      default:
        return isBidDeadlinePassed ? 'Bid Review' : 'Bidding';
    }
  } else {
    if (portfolioState.earliestState <= DealStates.READY_TO_SUBMIT) {
      return ProjectStatusDisplay[ProjectStatus.DRAFT];
    } else if (portfolioState.earliestState <= DealStates.MATCHING) {
      return ProjectStatusDisplay[ProjectStatus.IN_REVIEW];
    } else if (portfolioState.earliestState === DealStates.DECLINED && portfolioState.latestState === DealStates.DECLINED) {
      return 'Projects Declined';
    } else if (portfolioState.earliestState <= DealStates.DECLINED) {
      return isBidDeadlinePassed ? 'Bid Review' : 'Bidding';
    } else if (portfolioState.earliestState === DealStates.IN_DILIGENCE) {
      return ProjectStatusDisplay[ProjectStatus.IN_DILIGENCE];
    } else {
      return ProjectStatusDisplay[ProjectStatus.COMPLETED];
    }
  } 
}
