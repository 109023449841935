export const DealStates = {
  PROJECT_HAS_ERRORS: 0,
  RETURNED_TO_DRAFT: 1,
  READY_TO_SUBMIT: 2,
  IN_REVIEW: 3,
  MATCHING: 4,
  AWAITING_BID: 5,
  PENDING_BID: 6,
  DECLINED: 7,
  IN_DILIGENCE: 8,
  COMPLETED: 9,
};

export const MinDealState = Object.values(DealStates).reduce((min, current) => Math.min(min, current), 0);
export const MaxDealState = Object.values(DealStates).reduce((max, current) => Math.max(max, current), 0);